<template>
  <div class="create_resume">
    <div class="resume_header">
      <div class="ms-3">
        <router-link to="/">
          <span>
            <i class="fa fa-arrow-left me-1"></i>
          </span>
          Resumes
        </router-link>
      </div>
    </div>
    <div class="create_resume_wrapper">
      <div class="form_resume_wrapper">
        <div class="accordion_wrapper">
          <form class="resume_form" enctype="multipart/form-data" novalidate>
            <div class="accrodion_headers mt-3">
              <input type="hidden" v-model="resume.id" />
              <input
                type="text"
                placeholder=" Personal details"
                class="form-control header_input"
                v-model="resume.personalDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div class="mt-3">
              <div class="choose-img d-flex">
                <div>
                  <p>Photo</p>
                  <label for="choose" id="select_img">
                    <img src="/Capture.PNG" alt="img" id="image" />
                    <input
                      type="file"
                      id="choose"
                      accept=".png, .jpg, .jpeg"
                      @change="handleFileChange"
                    />
                  </label>
                </div>
                <div class="ms-3 w-100">
                  <div class="d-flex form_flex mb-2">
                    <div class="me-2 w-50">
                      <label for="name" class="form-label resume_label">
                        Given name
                      </label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="resume.name"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="w-50">
                      <label for="fname" class="form-label resume_label"
                        >Family name</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="resume.familyName"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                  </div>
                  <div class="mb-3 me-5 w-100">
                    <label for="email" class="form-label resume_label"
                      >Email address</label
                    >
                    <input
                      type="email"
                      class="form-control resume_input"
                      v-model="resume.email"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="headl" class="form-label resume_label"
                  >Headline</label
                >
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.headline"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="mb-3">
                <label for="number" class="form-label resume_label"
                  >Phone number</label
                >
                <input
                  type="tel"
                  class="form-control resume_input"
                  v-model="resume.phoneNumber"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="mb-3">
                <label for="address" class="form-label resume_label"
                  >Address</label
                >
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.address"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="d-flex mb-3">
                <div class="w-50 me-2">
                  <label for="code" class="form-label resume_label"
                    >Post code</label
                  >
                  <input
                    type="text"
                    class="form-control resume_input"
                    v-model="resume.postCode"
                    @input="debouncedUpdateResume"
                  />
                </div>
                <div class="w-50">
                  <label for="city" class="form-label resume_label">City</label>
                  <input
                    type="text"
                    class="form-control resume_input"
                    v-model="resume.city"
                    @input="debouncedUpdateResume"
                  />
                </div>
              </div>
              <div class="mb-3 hidden_input date_birth date_birthField">
                <div class="hidden_input_wrappr">
                  <label
                    for="date-birth"
                    class="form-label resume_label d-flex"
                  >
                    Date of birth
                  </label>

                  <div class="dropdown">
                    <button
                      class="removeField_Btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu remove_field">
                      <li
                        class="dropdown-item"
                        @click="deleteField('date_birthField')"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Remove field
                      </li>
                    </ul>
                  </div>
                </div>
                <input
                  type="date"
                  class="form-control resume_input"
                  v-model="resume.dateOfBirth"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="mb-3 hidden_input place_birth place_birthField">
                <div class="hidden_input_wrappr">
                  <label
                    for="date-birth"
                    class="form-label resume_label d-flex"
                  >
                    Place of birth
                  </label>

                  <div class="dropdown">
                    <button
                      class="removeField_Btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu remove_field">
                      <li
                        class="dropdown-item"
                        @click="deleteField('place_birthField')"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Remove field
                      </li>
                    </ul>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.placeOfBirth"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="mb-3 hidden_input driver_license driver_licenseField">
                <div class="hidden_input_wrappr">
                  <label
                    for="date-birth"
                    class="form-label resume_label d-flex"
                  >
                    Driver's license
                  </label>

                  <div class="dropdown">
                    <button
                      class="removeField_Btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu remove_field">
                      <li
                        class="dropdown-item"
                        @click="deleteField('driver_licenseField')"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Remove field
                      </li>
                    </ul>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.drivingLicense"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="mb-3 hidden_input gender_btn gender_btnField">
                <div class="hidden_input_wrappr">
                  <label
                    for="date-birth"
                    class="form-label resume_label d-flex"
                  >
                    Gender
                  </label>

                  <div class="dropdown">
                    <button
                      class="removeField_Btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu remove_field">
                      <li
                        class="dropdown-item"
                        @click="deleteField('gender_btnField')"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Remove field
                      </li>
                    </ul>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.gender"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div
                class="mb-3 hidden_input nationality_btn nationality_btnField"
              >
                <div class="hidden_input_wrappr">
                  <label
                    for="date-birth"
                    class="form-label resume_label d-flex"
                  >
                    Nationality
                  </label>

                  <div class="dropdown">
                    <button
                      class="removeField_Btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu remove_field">
                      <li
                        class="dropdown-item"
                        @click="deleteField('nationality_btnField')"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Remove field
                      </li>
                    </ul>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.nationality"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="mb-3 hidden_input civil_btn civil_btnField">
                <div class="hidden_input_wrappr">
                  <label
                    for="date-birth"
                    class="form-label resume_label d-flex"
                  >
                    Civil status
                  </label>

                  <div class="dropdown">
                    <button
                      class="removeField_Btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu remove_field">
                      <li
                        class="dropdown-item"
                        @click="deleteField('civil_btnField')"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Remove field
                      </li>
                    </ul>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.civilState"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="mb-3 hidden_input website_btn website_btnField">
                <div class="hidden_input_wrappr">
                  <label
                    for="date-birth"
                    class="form-label resume_label d-flex"
                  >
                    Website
                  </label>

                  <div class="dropdown">
                    <button
                      class="removeField_Btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu remove_field">
                      <li
                        class="dropdown-item"
                        @click="deleteField('website_btnField')"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Remove field
                      </li>
                    </ul>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.website"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="mb-3 hidden_input linkedin_btn linkedin_btnField">
                <div class="hidden_input_wrappr">
                  <label
                    for="date-birth"
                    class="form-label resume_label d-flex"
                  >
                    Linkedin
                  </label>

                  <div class="dropdown">
                    <button
                      class="removeField_Btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-vertical"></i>
                    </button>
                    <ul class="dropdown-menu remove_field">
                      <li
                        class="dropdown-item"
                        @click="deleteField('linkedin_btnField')"
                      >
                        <i class="fa fa-trash me-1"></i>
                        Remove field
                      </li>
                    </ul>
                  </div>
                </div>
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.linkedin"
                  @input="debouncedUpdateResume"
                />
              </div>

              <div class="form_widget">
                <button
                  type="button"
                  class="date_birth date_birthField"
                  @click="toggleFieldBtn('date_birth')"
                >
                  + Date of birth
                </button>
                <button
                  type="button"
                  class="place_birth place_birthField"
                  @click="toggleFieldBtn('place_birth')"
                >
                  + Place of birth
                </button>
                <button
                  type="button"
                  class="driver_license driver_licenseField"
                  @click="toggleFieldBtn('driver_license')"
                >
                  + Driver's license
                </button>
                <button
                  type="button"
                  class="gender_btn gender_btnField"
                  @click="toggleFieldBtn('gender_btn')"
                >
                  + Gender
                </button>
                <button
                  type="button"
                  class="nationality_btn nationality_btnField"
                  @click="toggleFieldBtn('nationality_btn')"
                >
                  + Nationality
                </button>
                <button
                  type="button"
                  class="civil_btn civil_btnField"
                  @click="toggleFieldBtn('civil_btn')"
                >
                  + Civil status
                </button>
                <button
                  type="button"
                  class="website_btn website_btnField"
                  @click="toggleFieldBtn('website_btn')"
                >
                  + Website
                </button>
                <button
                  type="button"
                  class="linkedin_btn linkedin_btnField"
                  @click="toggleFieldBtn('linkedin_btn')"
                >
                  + Linkedin
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- profile -->

        <div class="accordion_wrapper d-none">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Profile"
                  class="form-control resume_input header_input"
                  v-model="resume.profileDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personCourse"
                  aria-expanded="false"
                  aria-controls="personCourse"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personCourse" class="collapse">
              <div v-for="profile in resume.profiles" :key="profile.id">
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Description]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div>
                      <label class="form-label resume_label">Description</label>
                      <textarea
                        class="form-control resume_input"
                        cols="30"
                        rows="5"
                        style="resize: none"
                        v-model="profile.description"
                        @input="debouncedUpdateResume"
                      ></textarea>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addProfile">
                + Add Profile
              </button>
            </div>
          </form>
        </div>
        <!-- education -->

        <div class="accordion_wrapper">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Education"
                  class="form-control resume_input header_input"
                  v-model="resume.educationDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personEducation"
                  aria-expanded="false"
                  aria-controls="personEducation"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personEducation" class="collapse">
              <div v-for="education in resume.educations" :key="education.id">
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Education]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Education</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="education.education"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="d-flex mb-3">
                      <div class="w-50 me-2">
                        <label class="form-label resume_label">School</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="education.school"
                          @input="debouncedUpdateResume"
                        />
                      </div>
                      <div class="w-50">
                        <label class="form-label resume_label">City</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="education.city"
                          @input="debouncedUpdateResume"
                        />
                        <input type="hidden" v-model="education.person_id" />
                      </div>
                    </div>
                    <div class="d-flex gap-2">
                      <div class="w-50 mb-3">
                        <label class="form-label resume_label"
                          >Start Date</label
                        >
                        <div class="d-flex gap-2">
                          <select
                            class="form-select resume_input resume_select"
                            v-model="education.startMonth"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>

                          <select
                            class="form-select resume_input"
                            v-model="education.startYear"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="w-50 mb-3">
                        <label class="form-label resume_label">End Date</label>
                        <div class="d-flex gap-2">
                          <select
                            class="form-select resume_input resume_select"
                            v-model="education.endMonth"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>

                          <select
                            class="form-select resume_input"
                            v-model="education.endYear"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label class="form-label resume_label">Description</label>
                      <textarea
                        class="form-control resume_input"
                        cols="30"
                        rows="5"
                        style="resize: none"
                        v-model="education.description"
                        @input="debouncedUpdateResume"
                      ></textarea>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addEducation">
                + Add Education
              </button>
            </div>
          </form>
        </div>

        <!-- Employment -->

        <div class="accordion_wrapper">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Employment"
                  class="form-control resume_input header_input"
                  v-model="resume.employmentDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personEmployment"
                  aria-expanded="false"
                  aria-controls="personEmployment"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personEmployment" class="collapse">
              <div
                v-for="employment in resume.employments"
                :key="employment.id"
              >
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Position]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Position</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="employment.position"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="d-flex mb-3">
                      <div class="w-50 me-2">
                        <label class="form-label resume_label">Employer</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="employment.employer"
                          @input="debouncedUpdateResume"
                        />
                      </div>
                      <div class="w-50">
                        <label class="form-label resume_label">City</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="employment.city"
                          @input="debouncedUpdateResume"
                        />
                        <input type="hidden" v-model="employment.person_id" />
                      </div>
                    </div>
                    <div class="d-flex gap-2">
                      <div class="w-50 mb-3">
                        <label class="form-label resume_label"
                          >Start Date</label
                        >
                        <div class="d-flex gap-2">
                          <select
                            class="form-select resume_input resume_select"
                            v-model="employment.startMonth"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>

                          <select
                            class="form-select resume_input"
                            v-model="employment.startYear"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="w-50 mb-3">
                        <label class="form-label resume_label">End Date</label>
                        <div class="d-flex gap-2">
                          <select
                            class="form-select resume_input resume_select"
                            v-model="employment.endMonth"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>

                          <select
                            class="form-select resume_input"
                            v-model="employment.endYear"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label class="form-label resume_label">Description</label>
                      <textarea
                        class="form-control resume_input"
                        cols="30"
                        rows="5"
                        style="resize: none"
                        v-model="employment.description"
                        @input="debouncedUpdateResume"
                      ></textarea>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addEmployer">
                + Add Employment
              </button>
            </div>
          </form>
        </div>

        <!-- Skill -->

        <div class="accordion_wrapper">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Skill"
                  class="form-control resume_input header_input"
                  v-model="resume.skillDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personSkill"
                  aria-expanded="false"
                  aria-controls="personSkill"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personSkill" class="collapse">
              <div v-for="skill in resume.skills" :key="skill.id">
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Skill]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Skill</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="skill.skill"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="level" class="form-label resume_label"
                        >Level</label
                      >
                      <div class="range_div">
                        <input
                          type="range"
                          min="0"
                          max="5"
                          class="form-range"
                          step="1"
                          v-model="skill.skillLevel"
                          @input="debouncedUpdateResume"
                        />
                        <p>Make a choice</p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addSkill">
                + Add Skill
              </button>
            </div>
          </form>
        </div>

        <!-- language -->

        <div class="accordion_wrapper">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Languages"
                  class="form-control resume_input header_input"
                  v-model="resume.languageDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personLanguage"
                  aria-expanded="false"
                  aria-controls="personLanguage"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personLanguage" class="collapse">
              <div v-for="language in resume.languages" :key="language.id">
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Language]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Language</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="language.language"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="mb-3">
                      <label for="level" class="form-label resume_label"
                        >Level</label
                      >
                      <div class="range_div">
                        <input
                          type="range"
                          min="0"
                          max="5"
                          class="form-range"
                          step="1"
                          v-model="language.languageLevel"
                          @input="debouncedUpdateResume"
                        />
                        <p>Make a choice</p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addLanguage">
                + Add Language
              </button>
            </div>
          </form>
        </div>

        <!-- hobby -->

        <div class="accordion_wrapper">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Hobbies"
                  class="form-control resume_input header_input"
                  v-model="resume.hobbiesDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personHobby"
                  aria-expanded="false"
                  aria-controls="personHobby"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personHobby" class="collapse">
              <div v-for="hobby in resume.hobbies" :key="hobby.id">
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Hobby]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Hobby</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="hobby.hobby"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                  </div>
                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addHobby">
                + Add Hobbies
              </button>
            </div>
          </form>
        </div>

        <!-- courses -->

        <div class="accordion_wrapper">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Courses"
                  class="form-control resume_input header_input"
                  v-model="resume.coursesDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personCourse"
                  aria-expanded="false"
                  aria-controls="personCourse"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personCourse" class="collapse">
              <div v-for="course in resume.courses" :key="course.id">
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Course]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Course</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="course.course"
                        @input="debouncedUpdateResume"
                      />
                      <input type="hidden" v-model="course.person_id" />
                    </div>

                    <div>
                      <label class="form-label resume_label">Period</label>
                      <div class="d-flex gap-3 w-100 mb-3">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="course.period_month"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                        <select
                          class="form-select resume_input"
                          v-model="course.period_year"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label class="form-label resume_label">Description</label>
                      <textarea
                        class="form-control resume_input"
                        cols="30"
                        rows="5"
                        style="resize: none"
                        v-model="course.description"
                        @input="debouncedUpdateResume"
                      ></textarea>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addCourse">
                + Add Course
              </button>
            </div>
          </form>
        </div>

        <!-- internship -->

        <div class="accordion_wrapper d-none">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Internships"
                  class="form-control resume_input header_input"
                  v-model="resume.internshipDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personInternship"
                  aria-expanded="false"
                  aria-controls="personInternship"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personInternship" class="collapse">
              <div
                v-for="internship in resume.internships"
                :key="internship.id"
              >
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Position]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Position</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="internship.position"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="d-flex mb-3">
                      <div class="w-50 me-2">
                        <label class="form-label resume_label">Employer</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="internship.employer"
                          @input="debouncedUpdateResume"
                        />
                      </div>
                      <div class="w-50">
                        <label class="form-label resume_label">City</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="internship.city"
                          @input="debouncedUpdateResume"
                        />
                        <input type="hidden" v-model="internship.person_id" />
                      </div>
                    </div>
                    <div class="d-flex gap-2">
                      <div class="w-50 mb-3">
                        <label class="form-label resume_label"
                          >Start Date</label
                        >
                        <div class="d-flex gap-2">
                          <select
                            class="form-select resume_input resume_select"
                            v-model="internship.startMonth"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>

                          <select
                            class="form-select resume_input"
                            v-model="internship.startYear"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="w-50 mb-3">
                        <label class="form-label resume_label">End Date</label>
                        <div class="d-flex gap-2">
                          <select
                            class="form-select resume_input resume_select"
                            v-model="internship.endMonth"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>

                          <select
                            class="form-select resume_input"
                            v-model="internship.endYear"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label class="form-label resume_label">Description</label>
                      <textarea
                        class="form-control resume_input"
                        cols="30"
                        rows="5"
                        style="resize: none"
                        v-model="internship.description"
                        @input="debouncedUpdateResume"
                      ></textarea>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addEmployer">
                + Add Employment
              </button>
            </div>
          </form>
        </div>

        <!-- activities -->

        <div class="accordion_wrapper d-none">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Extracurricular activities"
                  class="form-control resume_input header_input"
                  v-model="resume.extra_activitieDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personActivity"
                  aria-expanded="false"
                  aria-controls="personActivity"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personActivity" class="collapse">
              <div v-for="activity in resume.activities" :key="activity.id">
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Position]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Position</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="activity.position"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="d-flex mb-3">
                      <div class="w-50 me-2">
                        <label class="form-label resume_label">Employer</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="activity.employer"
                          @input="debouncedUpdateResume"
                        />
                      </div>
                      <div class="w-50">
                        <label class="form-label resume_label">City</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="activity.city"
                          @input="debouncedUpdateResume"
                        />
                        <input type="hidden" v-model="activity.person_id" />
                      </div>
                    </div>
                    <div class="d-flex gap-2">
                      <div class="w-50 mb-3">
                        <label class="form-label resume_label"
                          >Start Date</label
                        >
                        <div class="d-flex gap-2">
                          <select
                            class="form-select resume_input resume_select"
                            v-model="activity.startMonth"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>

                          <select
                            class="form-select resume_input"
                            v-model="activity.startYear"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="w-50 mb-3">
                        <label class="form-label resume_label">End Date</label>
                        <div class="d-flex gap-2">
                          <select
                            class="form-select resume_input resume_select"
                            v-model="activity.endMonth"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Month</option>
                            <option value="January">January</option>
                            <option value="February">February</option>
                            <option value="March">March</option>
                            <option value="April">April</option>
                            <option value="May">May</option>
                            <option value="June">June</option>
                            <option value="July">July</option>
                            <option value="August">August</option>
                            <option value="September">September</option>
                            <option value="October">October</option>
                            <option value="November">November</option>
                            <option value="December">December</option>
                          </select>

                          <select
                            class="form-select resume_input"
                            v-model="activity.endYear"
                            @change="debouncedUpdateResume"
                          >
                            <option selected value="">Year</option>
                            <option
                              v-for="year in years"
                              :key="year"
                              :value="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label class="form-label resume_label">Description</label>
                      <textarea
                        class="form-control resume_input"
                        cols="30"
                        rows="5"
                        style="resize: none"
                        v-model="activity.description"
                        @input="debouncedUpdateResume"
                      ></textarea>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addEmployer">
                + Add Employment
              </button>
            </div>
          </form>
        </div>

        <!-- reference -->

        <div class="accordion_wrapper d-none">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="References"
                  class="form-control resume_input header_input"
                  v-model="resume.referenceDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personReference"
                  aria-expanded="false"
                  aria-controls="personReference"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personReference" class="collapse">
              <div v-if="resume.references.length === 0">
                <p class="m-0">References available upon request.</p>
              </div>

              <div
                v-for="reference in resume.references"
                :key="reference.id"
                v-else
              >
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Reference]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Name</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="reference.name"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="d-flex mb-3">
                      <div class="w-50 me-2">
                        <label class="form-label resume_label"
                          >organization</label
                        >
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="reference.organization"
                          @input="debouncedUpdateResume"
                        />
                      </div>
                      <div class="w-50">
                        <label class="form-label resume_label">City</label>
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="reference.city"
                          @input="debouncedUpdateResume"
                        />
                        <input type="hidden" v-model="reference.person_id" />
                      </div>
                    </div>
                    <div class="d-flex mb-3">
                      <div class="w-50 me-2">
                        <label class="form-label resume_label"
                          >Phone Number</label
                        >
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="reference.phone"
                          @input="debouncedUpdateResume"
                        />
                      </div>
                      <div class="w-50">
                        <label class="form-label resume_label"
                          >Email Address</label
                        >
                        <input
                          type="text"
                          class="form-control resume_input"
                          v-model="reference.email"
                          @input="debouncedUpdateResume"
                        />
                        <input type="hidden" v-model="reference.person_id" />
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addRefecence">
                + Add Reference
              </button>
            </div>
          </form>
        </div>

        <!-- Qulaity -->

        <div class="accordion_wrapper d-none">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Qualities"
                  class="form-control resume_input header_input"
                  v-model="resume.qualityDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personQuality"
                  aria-expanded="false"
                  aria-controls="personQuality"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personQuality" class="collapse">
              <div v-for="quality in resume.qualities" :key="quality.id">
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Quality]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Quality</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="quality.Quality"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                  </div>
                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addQuality">
                + Add Quality
              </button>
            </div>
          </form>
        </div>

        <!-- certificate -->

        <div class="accordion_wrapper d-none">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Extracurricular activities"
                  class="form-control resume_input header_input"
                  v-model="resume.certificateDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personCertificate"
                  aria-expanded="false"
                  aria-controls="personCertificate"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personCertificate" class="collapse">
              <div
                v-for="certificate in resume.certificates"
                :key="certificate.id"
              >
                <div class="educationWra resume_formBorder mb-2">
                  <div class="form_openFiedls d-none">
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div>[Certificate]</div>
                      <button><i class="fa fa-pencil"></i></button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div class="mb-3">
                      <label for="address" class="form-label resume_label"
                        >Certificate</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="certificate.certificate"
                        @input="debouncedUpdateResume"
                      />
                    </div>

                    <div>
                      <label class="form-label resume_label">Period</label>
                      <div class="d-flex gap-3 w-100 mb-3">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="certificate.monthPeriod"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                        <select
                          class="form-select resume_input"
                          v-model="certificate.yearPeriod"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <label class="form-label resume_label">Description</label>
                      <textarea
                        class="form-control resume_input"
                        cols="30"
                        rows="5"
                        style="resize: none"
                        v-model="certificate.description"
                        @input="debouncedUpdateResume"
                      ></textarea>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addCertificate">
                + Add Certificate
              </button>
            </div>
          </form>
        </div>

        <!-- achievement -->

        <div class="accordion_wrapper d-none">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Achievements"
                  class="form-control resume_input header_input"
                  v-model="resume.achievementDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personAchievement"
                  aria-expanded="false"
                  aria-controls="personAchievement"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personAchievement" class="collapse">
              <div class="educationWra resume_formBorder mb-2">
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="address" class="form-label resume_label"
                      >Description</label
                    >
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="resume.achievement.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!-- signature -->

        <div class="accordion_wrapper d-nones">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Signature"
                  class="form-control resume_input header_input"
                  v-model="resume.signatureDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personSignature"
                  aria-expanded="false"
                  aria-controls="personSignature"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personSignature" class="collapse">
              <div class="educationWra resume_formBorder mb-2">
                <div class="d-flex mb-3">
                  <div class="w-50 me-2">
                    <label class="form-label resume_label">City</label>
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="resume.city"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                  <div class="w-50">
                    <label class="form-label resume_label">Date</label>
                    <input
                      type="date"
                      class="form-control resume_input"
                      v-model="resume.signatures.date"
                      @input="debouncedUpdateResume"
                    />

                    <input
                      type="hidden"
                      v-model="resume.signatures.person_id"
                    />
                  </div>
                </div>

                <div class="signature_Wrapper">
                  <div>
                    <label class="form-label resume_label">Signature</label>
                    <div class="dropdown">
                      <button
                        class="btn"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#">Delete</a></li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="signture_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#signtureModal"
                    @click="renderFunction()"
                  ></div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!--signature Modal -->
        <div
          class="modal fade signatureModal"
          id="signtureModal"
          tabindex="-1"
          aria-labelledby="signtureModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="signtureModalLabel">
                  Signature
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="closeModel"
                ></button>
              </div>
              <div class="modal-body">
                <div class="tabsSignature">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="pills-draw-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-draw"
                        type="button"
                        role="tab"
                        aria-controls="pills-draw"
                        aria-selected="true"
                        style="border-right: 1px solid #949494"
                      >
                        Draw
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-type-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-type"
                        type="button"
                        role="tab"
                        aria-controls="pills-type"
                        aria-selected="false"
                        style="border-right: 1px solid #949494"
                      >
                        Type
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="pills-upload-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-upload"
                        type="button"
                        role="tab"
                        aria-controls="pills-upload"
                        aria-selected="false"
                      >
                        Upload
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-draw"
                      role="tabpanel"
                      aria-labelledby="pills-draw-tab"
                      tabindex="0"
                    >
                      <div :key="renderkey" class="signature_fields">
                        <VueSignaturePad
                          id="signature"
                          style="width: 100%; height: 200px"
                          ref="signaturePad"
                          :options="options"
                          @click="handleSignatureChange"
                        />
                        <div class="button_wrapper">
                          <div class="btns">
                            <button @click="changeColor('black')" class="black">
                              <i
                                v-show="options.penColor === 'black'"
                                class="fa fa-check"
                              ></i>
                            </button>
                            <button @click="changeColor('red')" class="red">
                              <i
                                v-show="options.penColor === 'red'"
                                class="fa fa-check"
                              ></i>
                            </button>
                            <button @click="changeColor('blue')" class="blue">
                              <i
                                v-show="options.penColor === 'blue'"
                                class="fa fa-check"
                              ></i>
                            </button>

                            <p
                              id="clear"
                              class="d-none"
                              @click="handleClearSignautre"
                            >
                              <i class="fa fa-eraser"></i>
                              Erase
                            </p>
                          </div>
                          <div>
                            <button
                              data-bs-dismiss="modal"
                              type="button"
                              class="btn done"
                              @click="save"
                              id="done"
                              :class="{ 'empty-canvas': isCanvasEmpty }"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-type"
                      role="tabpanel"
                      aria-labelledby="pills-type-tab"
                      tabindex="0"
                    >
                      <div class="signature_fields">
                        <input
                          type="text"
                          id="signatureInput"
                          :class="{
                            black: options.penColor === 'black',
                            red: options.penColor === 'red',
                            blue: options.penColor === 'blue',
                            dancing: signatureFamily === 'dancing',
                            parisienne: signatureFamily === 'parisienne',
                            sacramento: signatureFamily === 'sacramento',
                          }"
                          :value="signatureType"
                          @input="updateSignatureValue"
                        />
                        <div class="button_wrapper">
                          <div class="btns">
                            <button @click="changeColor('black')" class="black">
                              <i
                                v-show="options.penColor === 'black'"
                                class="fa fa-check"
                              ></i>
                            </button>
                            <button @click="changeColor('red')" class="red">
                              <i
                                v-show="options.penColor === 'red'"
                                class="fa fa-check"
                              ></i>
                            </button>
                            <button @click="changeColor('blue')" class="blue">
                              <i
                                v-show="options.penColor === 'blue'"
                                class="fa fa-check"
                              ></i>
                            </button>

                            <div class="dropdown fontDiv">
                              <button
                                class="btn dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Aa Font
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <a
                                    @click="changeFamily('dancing')"
                                    :class="{
                                      active: signatureFamily === 'dancing',
                                    }"
                                    class="dropdown-item font font-1"
                                    href="#"
                                  >
                                    <p>
                                      <i
                                        v-show="signatureFamily === 'dancing'"
                                        class="fa fa-check"
                                      >
                                      </i>
                                    </p>
                                    {{
                                      signatureType
                                        ? signatureType
                                        : "Signature"
                                    }}</a
                                  >
                                </li>
                                <li>
                                  <a
                                    @click="changeFamily('parisienne')"
                                    :class="{
                                      active: signatureFamily === 'parisienne',
                                    }"
                                    class="dropdown-item font font-2"
                                    href="#"
                                  >
                                    <p>
                                      <i
                                        v-show="
                                          signatureFamily === 'parisienne'
                                        "
                                        class="fa fa-check"
                                      >
                                      </i>
                                    </p>

                                    {{
                                      signatureType
                                        ? signatureType
                                        : "Signature"
                                    }}</a
                                  >
                                </li>
                                <li>
                                  <a
                                    @click="changeFamily('sacramento')"
                                    :class="{
                                      active: signatureFamily === 'sacramento',
                                    }"
                                    class="dropdown-item font font-3"
                                    href="#"
                                  >
                                    <p>
                                      <i
                                        v-show="
                                          signatureFamily === 'sacramento'
                                        "
                                        class="fa fa-check"
                                      >
                                      </i>
                                    </p>
                                    {{
                                      signatureType
                                        ? signatureType
                                        : "Signature"
                                    }}</a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div>
                            <button
                              data-bs-dismiss="modal"
                              type="button"
                              class="btn done"
                              @click="save"
                              :class="{ 'empty-canvas': signatureType === '' }"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-upload"
                      role="tabpanel"
                      aria-labelledby="pills-upload-tab"
                      tabindex="0"
                    >
                      <div class="signature_fields">
                        <div class="upload_image" ref="uploadedImage">
                          <div
                            v-if="!imageUrl"
                            @click="openFileInput"
                            @drop="handleDrop"
                            @dragover.prevent
                          >
                            <p>
                              Drag & drop your image here or click to upload.
                            </p>
                            <input
                              type="file"
                              @change="handleFileInput"
                              accept="image/*"
                              style="display: none"
                              ref="fileInput"
                            />
                            <button>Upload Image</button>
                          </div>
                          <img
                            :class="{
                              black: options.penColor === 'black',
                              red: options.penColor === 'red',
                              blue: options.penColor === 'blue',
                            }"
                            :src="imageUrl"
                            alt="Uploaded Image"
                            v-if="imageUrl"
                            ref="imagefilter"
                          />
                        </div>
                        <div class="button_wrapper">
                          <div class="btns">
                            <button @click="changeColor('black')" class="black">
                              <i
                                v-show="options.penColor === 'black'"
                                class="fa fa-check"
                              ></i>
                            </button>
                            <button @click="changeColor('red')" class="red">
                              <i
                                v-show="options.penColor === 'red'"
                                class="fa fa-check"
                              ></i>
                            </button>
                            <button @click="changeColor('blue')" class="blue">
                              <i
                                v-show="options.penColor === 'blue'"
                                class="fa fa-check"
                              ></i>
                            </button>

                            <p
                              @click="deleteImage"
                              :class="{
                                none: !imageUrl,
                              }"
                            >
                              <i class="fa fa-eraser"></i>
                              Erase
                            </p>
                          </div>
                          <div>
                            <button
                              data-bs-dismiss="modal"
                              type="button"
                              class="btn done"
                              @click="save"
                              :class="{ 'empty-canvas': !imageUrl }"
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- footer -->

        <div class="accordion_wrapper d-none">
          <form class="resume_form">
            <div class="accrodion_header">
              <div class="form_header">
                <input
                  type="text"
                  placeholder="Footrer"
                  class="form-control resume_input header_input"
                  v-model="resume.footerDetails"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div>
                <button
                  class="toggle_btn btn collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#personFooter"
                  aria-expanded="false"
                  aria-controls="personFooter"
                >
                  <i class="fa fa-angle-up"></i>
                </button>
              </div>
            </div>
            <div id="personFooter" class="collapse">
              <div class="educationWra resume_formBorder mb-2">
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="address" class="form-label resume_label"
                      >Description</label
                    >
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="resume.footer.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="field_widgets">
          <button type="button" class="profile_Field" @click="mainFieldBtn">
            + Profile
          </button>
          <button type="button" class="internship_Field" @click="mainFieldBtn">
            + Internships
          </button>
          <button type="button" class="activity_Field" @click="mainFieldBtn">
            + Extracurricular activities
          </button>
          <button type="button" class="reference_Field" @click="mainFieldBtn">
            + References
          </button>
          <button type="button" class="quality_Field" @click="mainFieldBtn">
            + Qualities
          </button>
          <button type="button" class="certificate_Field" @click="mainFieldBtn">
            + Certificates</button
          ><button
            type="button"
            class="achievement_Field"
            @click="mainFieldBtn"
          >
            + Achievements</button
          ><button type="button" class="profile_Field" @click="mainFieldBtn">
            + Signature</button
          ><button type="button" class="footer_Field" @click="mainFieldBtn">
            + Footer
          </button>
        </div>
      </div>
      <div class="resumePages">
        <div class="personalFormEmpty" id="personalForm" @click="scaleFunction">
          <div class="personalForm_header"></div>
          <div class="personalForm_body">
            <div class="personalForm_main"></div>
            <div class="personalForm_aside"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>



<script>
import axios from "axios";
import { debounce } from "lodash";

export default {
  name: "CvMaker-form",

  data() {
    return {
      resume: {
        personalDetails: "Person detalis",
        id: "",
        profileImage: null,
        name: "",
        familyName: "",
        email: "",
        headline: "",
        phoneNumber: "",
        address: "",
        postCode: "",
        city: "",
        dateOfBirth: "",
        placeOfBirth: "",
        drivingLicense: "",
        gender: "",
        nationality: "",
        civilState: "",
        website: "",
        linkedin: "",
        educationDetails: "Education",
        employmentDetails: "Employment",
        skillDetails: "Skills",
        languageDetails: "Languages",
        hobbiesDetails: "Hobbies",
        coursesDetails: "Courses",
        profileDetails: "Profile",
        internshipDetails: "Internships",
        extra_activitieDetails: "Extracurricular activities",
        referenceDetails: "References",
        qualityDetails: "Qualities",
        certificateDetails: "Certificates",
        achievementDetails: "Achievements",
        signatureDetails: "Signature",
        footerDetails: "Footer",

        educations: [
          {
            education: "",
            school: "",
            city: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            description: "",
            person_id: "",
          },
        ],
        employments: [
          {
            position: "",
            employer: "",
            city: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            description: "",
            person_id: "",
          },
        ],
        skills: [
          {
            skill: "",
            skillLevel: 0,
            person_id: "",
          },
        ],
        languages: [
          {
            language: "",
            languageLevel: 0,
            person_id: "",
          },
        ],
        hobbies: [
          {
            hobby: "",
            person_id: "",
          },
        ],
        courses: [
          {
            course: "",
            period_month: "",
            period_year: "",
            description: "",
            person_id: "",
          },
        ],
        profiles: [
          {
            description: "",
            person_id: "",
          },
        ],
        internships: [
          {
            position: "",
            employer: "",
            city: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            description: "",
            person_id: "",
          },
        ],
        activities: [
          {
            position: "",
            employer: "",
            city: "",
            startMonth: "",
            startYear: "",
            endMonth: "",
            endYear: "",
            description: "",
            person_id: "",
          },
        ],
        references: [],
        qualities: [
          {
            quality: "",
            person_id: "",
          },
        ],
        certificates: [
          {
            certificate: "",
            monthPeriod: "",
            yearPeriod: "",
            description: "",
            person_id: "",
          },
        ],
        signatures: {
          date: "",
          signature: "",
          person_id: "",
        },
        achievement: {
          description: "",
          person_id: "",
        },
        footer: {
          description:
            "I consent to the processing of my personal data for the purpose of recruitment for the position to which I am applying.",
       status:false,
       },
      },
      years: [],
      options: {
        penColor: "black",
      },
      renderkey: 0,
      isCanvasEmpty: true,
      signatureType: "",
      signatureFamily: "dancing",
      imageUrl: null,
    };
  },

  mounted() {
    this.imageLoad();
    this.addToggleBtnEventListeners();
    this.increaseHeaderInputSize();
    if (!sessionStorage.getItem("pageLoaded")) {
      this.scaleFunction();
      this.deleteField();
      this.toggleFieldBtn();
      sessionStorage.setItem("pageLoaded", "true");
    }
  },
  created() {
    this.generateYears();
      this.debouncedUpdateResume = debounce(this.saveResume, 800);

  },

  methods: {
    renderFunction() {
      setTimeout(() => {
        this.renderkey = this.renderkey + 1;
      }, 400);
      setTimeout(() => {
        document.querySelector("#done").classList.add("empty-canvas");
      }, 450);
    },
    save() {
      const { data } = this.$refs.signaturePad.saveSignature();
      if (data !== undefined) {
        this.resume.signatures.signature = data;
        this.saveResume();
      } else if (this.hasInputValue()) {
        const inputElement = document.getElementById("signatureInput");
        const inputValue = inputElement.value.trim();
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = inputElement.offsetWidth;
        canvas.height = 200;
        console.log(canvas.width);
        const fontFamily = window.getComputedStyle(inputElement).fontFamily;
        const fontSize = window.getComputedStyle(inputElement).fontSize;
        const textColor = window.getComputedStyle(inputElement).color;
        ctx.font = `${fontSize} ${fontFamily}`;
        const textWidth = ctx.measureText(inputValue).width;
        const x = (canvas.width - textWidth) / 2;
        const y = canvas.height / 1.99;
        ctx.fillStyle = textColor;
        ctx.fillText(inputValue, x, y);
        const inputValueAsImage = canvas.toDataURL();
        this.resume.signatures.signature = inputValueAsImage;
        this.saveResume();
      } else if (this.imageUrl !== null) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = this.$refs.uploadedImage;
        const imagefilter = this.$refs.imagefilter;
        canvas.width = image.offsetWidth;
        canvas.height = 200;
        const textFilter = window.getComputedStyle(imagefilter).filter;
        const images = new Image();
        images.src = this.imageUrl;
        images.onload = () => {
          ctx.filter = textFilter;
          ctx.drawImage(images, 0, 0);
          const dataURL = canvas.toDataURL();
          this.resume.signatures.signature = dataURL;
          this.saveResume();
        };
      } else {
        console.log("No signature or input value or Image to save.");
      }
      this.signatureType = "";
      this.imageUrl = null;
    },
    hasInputValue() {
      const inputElement = document.getElementById("signatureInput");
      return inputElement && inputElement.value.trim() !== "";
    },
    closeModel() {
      this.signatureType = "";
      this.imageUrl = null;
    },
    handleSignatureChange(data) {
      this.isCanvasEmpty = data.isEmpty;
      document.querySelector("#done").classList.remove("empty-canvas");
      document.querySelector("#clear").classList.remove("d-none");
    },
    changeColor(color) {
      this.options = {
        penColor: color,
      };
    },
    handleClearSignautre() {
      this.$refs.signaturePad.clearSignature();
      document.querySelector("#clear").classList.add("d-none");
      document.querySelector("#done").classList.add("empty-canvas");
    },
    updateSignatureValue(event) {
      this.signatureType = event.target.value;
      const length = this.signatureType.length;
      const inputElement = document.getElementById("signatureInput");
      console.log(length);

      let fontSize = "55px";
      if (length == 30) {
        fontSize = "36px";
        inputElement.maxLength = 30;
      } else if (length >= 25) {
        fontSize = "40px";
      } else if (length >= 20) {
        fontSize = "48px";
      } else {
        fontSize = "55px";
      }
      inputElement.style.fontSize = fontSize;
    },

    changeFamily(font) {
      this.signatureFamily = font;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileInput(event) {
      const files = event.target.files;
      this.handleFiles(files);
    },
    handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },
    handleFiles(files) {
      if (files.length > 0) {
        const file = files[0];
        this.displayImage(file);
      }
    },
    displayImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    deleteImage() {
      this.imageUrl = null;
    },

    generateYears() {
      const startYear = 1920;
      const endYear = 2030;
      for (let year = endYear; year >= startYear; year--) {
        this.years.push(year);
      }
    },
    addEducation() {
      this.resume.educations.push({
        education: "",
        school: "",
        city: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        description: "",
      });
      this.saveResume();
    },
    addEmployer() {
      this.resume.employments.push({
        position: "",
        employer: "",
        city: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        description: "",
      });
      this.saveResume();
    },
    addSkill() {
      this.resume.skills.push({
        skill: "",
        skillLevel: 0,
      });
      this.saveResume();
    },
    addLanguage() {
      this.resume.languages.push({
        language: "",
        languageLevel: 0,
      });
      this.saveResume();
    },
    addHobby() {
      this.resume.hobbies.push({
        hobby: "",
      });
      this.saveResume();
    },
    addCourse() {
      this.resume.courses.push({
        course: "",
        period_month: "",
        period_year: "",
        description: "",
      });
      this.saveResume();
    },
    addProfile() {
      this.resume.courses.push({
        description: "",
      });
      this.saveResume();
    },
    addInternship() {
      this.resume.internships.push({
        position: "",
        employer: "",
        city: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        description: "",
      });
      this.saveResume();
    },
    addActivity() {
      this.resume.activities.push({
        position: "",
        employer: "",
        city: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        description: "",
      });
      this.saveResume();
    },
    addRefecence() {
      this.resume.references.push({
        name: "",
        organization: "",
        city: "",
        phone: "",
        email: "",
      });
      this.saveResume();
    },
    addQuality() {
      this.resume.qualities.push({
        quality: "",
      });
      this.saveResume();
    },
    addCertificate() {
      this.resume.certificates.push({
        certificate: "",
        monthPeriod: "",
        yearPeriod: "",
        description: "",
      });
      this.saveResume();
    },
    addToggleBtnEventListeners() {
      const toggleBtns = document.querySelectorAll(".toggle_btn.collapsed");
      toggleBtns.forEach((button) => {
        button.addEventListener("click", function () {
          const accordionHeader = this.closest(".accrodion_header");
          accordionHeader.classList.toggle("active");
        });
      });
    },
    increaseHeaderInputSize() {
      const inputs = document.querySelectorAll(".header_input");
      inputs.forEach((input) => {
        input.addEventListener("input", () => {
          const maxWidthChars = 60;
          const minWidth = 150;
          const charWidth = 8;

          const inputValueLength = input.value.length;
          let width = inputValueLength * charWidth;

          if (width < minWidth) {
            width = minWidth;
          } else if (inputValueLength > maxWidthChars) {
            width = maxWidthChars * charWidth;
          }

          input.style.width = width + "px";
        });
      });
    },
    imageLoad() {
      let profilepic = document.getElementById("image");
      let inputfile = document.getElementById("choose");
      inputfile.onchange = function () {
        profilepic.src = URL.createObjectURL(inputfile.files[0]);
      };
    },
    toggleFieldBtn(className) {
      const correspondingButton = document.querySelector(
        `.resume_form .hidden_input.${className}`
      );
      const clickButton = document.querySelector(
        `.form_widget  button.${className}`
      );
      if (correspondingButton) {
        correspondingButton.classList.add("d-block");
        correspondingButton.classList.remove("d-none");
      }
      if (clickButton) {
        clickButton.classList.add("d-none");
      } else {
        ("error");
      }
    },
    mainFieldBtn() {
      this.saveResume();
    },
    deleteField(className) {
      if (className == "date_birthField") {
        this.resume.dateOfBirth = "";
      }
      if (className == "place_birthField") {
        this.resume.placeOfBirth = "";
      }
      if (className == "driver_licenseField") {
        this.resume.drivingLicense = "";
      }
      if (className == "gender_btnField") {
        this.resume.gender = "";
      }
      if (className == "nationality_btnField") {
        this.resume.nationality = "";
      }
      if (className == "civil_btnField") {
        this.resume.civilState = "";
      }
      if (className == "website_btnField") {
        this.resume.website = "";
      }
      if (className == "linkedin_btnField") {
        this.resume.linkedin = "";
      }

      const correspondingButton = document.querySelector(
        `.resume_form .hidden_input.${className}`
      );
      const correspondingButtonParent = document.querySelector(
        `.form_widget button.${className}`
      );

      if (correspondingButton) {
        correspondingButton.classList.add("d-none");
        correspondingButton.classList.remove("d-block");
      }
      if (correspondingButtonParent) {
        correspondingButtonParent.classList.remove("d-none");
      }
      this.saveResume();
    },
    scaleFunction() {
      const personalForms = document.getElementById("personalForm");
      personalForms.classList.toggle("zoom");
    },
    async saveResume() {
      try {
        const formData = new FormData();
        formData.append("resume", JSON.stringify(this.resume));

        const personalResponse = await axios.post(
          process.env.VUE_APP_API_URL +"/person_detail",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (personalResponse.status === 200) {
          this.resume.id = personalResponse.data.id;
          this.$router.push({
            name: "resumesEdit",
            params: { id: this.resume.id },
          });
        }
      } catch (error) {
        console.error("Error submitting resume:", error);
      }
    },

    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = async () => {
        const profileImageBase64 = reader.result;
        this.resume.profileImage = profileImageBase64;
        console.log('img',profileImageBase64)
        this.saveResume();
      };
    },
  },
};
</script>

 <style lang="scss">
 @import '../styles/download.scss';

.hidden_input {
  display: none;
}
</style>

