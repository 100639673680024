<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "App",
  computed: {
    isDashboard() {
      return this.$route.path === "/";
    },
  },
};
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
</style>
