
<template>

<div class="notfound">
  <h2>404 Not found.</h2>
    <router-link class=" btn btn-primary" to="/">Home</router-link>

</div>
</template>


<style lang="scss" scoped>
.notfound {
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    height: 200px;

    h2{
        font-weight: 700;
    }
}
</style>
