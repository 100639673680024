// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './routes/routes';


// createApp(App)
//   .use(router).mount('#app');

import { createApp } from 'vue';
import App from './App.vue';
import router from './routes/routes';
import VueSignaturePad from 'vue-signature-pad';


const app = createApp(App);
app.use(router);
app.use(VueSignaturePad);
app.mount('#app');

