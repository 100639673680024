import { createRouter, createWebHistory } from 'vue-router';
import NotFound from '../components/NotFound.vue';
import resumes from '../components/CvMaker-form.vue';
import resumesEdit from '../components/CvMaker-formEdit.vue';
import Home from '../components/Home.vue';

const routes = [
  { path: '/', component: Home, name:'HomePage' },
  { path: '/resume',  component: resumes },
  { path: '/resume/edit/:id?', name:'resumesEdit',component: resumesEdit },
  {
    path: "/:notFound(.*)",
    component: NotFound,
  },
  ];
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;