<template>
<div class="create_resume">
  <div class="resume_header">
    <div class="ms-3">
      <router-link to="/">
        <span>
          <i class="fa fa-arrow-left me-1"></i>
        </span>
        Resumes
      </router-link>
    </div>
  </div>
  <div class="create_resume_wrapper">
    <div class="form_resume_wrapper">
      <div class="accordion_wrapper">
        <form class="resume_form" enctype="multipart/form-data">
          <div class="mt-3">
            <input type="hidden" v-model="resume.id" />
            <input
              type="text"
              placeholder=" Personal details"
              class="form-control header_input"
              v-model="resume.personalDetails"
              @input="updateResume"
            />
          </div>
          <div class="mt-3">
            <div class="choose-img d-flex">
              <div>
                <div class="d-flex justify-content-between">
                  <p>Photo</p>
                  <div class="dropdown deleteDropdown">
                    <button
                      class="btn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-ellipsis-v"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a
                          class="dropdown-item"
                          @click="delete_Image"
                          href="#"
                          >Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <label for="choose" id="select_img">
                  <img
                    :src="
                      resume.profileImage
                        ? resume.profileImage
                        : '/Capture.PNG'
                    "
                    alt="img"
                    id="image"
                  />
                  <input
                    type="file"
                    id="choose"
                    accept=".png, .jpg, .jpeg"
                    @change="handleFileChange"
                  />
                </label>
              </div>
              <div class="ms-3 w-100">
                <div class="d-flex form_flex mb-2">
                  <div class="me-2 w-50">
                    <label for="name" class="form-label resume_label">
                      Given name
                    </label>
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="resume.name"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                  <div class="w-50">
                    <label for="fname" class="form-label resume_label"
                      >Family name</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="resume.familyName"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                </div>
                <div class="mb-3 me-5 w-100">
                  <label for="email" class="form-label resume_label"
                    >Email address</label
                  >
                  <input
                    type="email"
                    class="form-control resume_input"
                    v-model="resume.email"
                    @input="debouncedUpdateResume"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="headl" class="form-label resume_label"
                >Headline</label
              >
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.headline"
                @input="debouncedUpdateResume"
              />
            </div>
            <div class="mb-3">
              <label for="number" class="form-label resume_label"
                >Phone number</label
              >
              <input
                type="tel"
                class="form-control resume_input"
                v-model="resume.phoneNumber"
                @input="debouncedUpdateResume"
              />
            </div>
            <div class="mb-3">
              <label for="address" class="form-label resume_label"
                >Address</label
              >
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.address"
                @input="debouncedUpdateResume"
              />
            </div>
            <div class="d-flex mb-3">
              <div class="w-50 me-2">
                <label for="code" class="form-label resume_label"
                  >Post code</label
                >
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.postCode"
                  @input="debouncedUpdateResume"
                />
              </div>
              <div class="w-50">
                <label for="city" class="form-label resume_label">City</label>
                <input
                  type="text"
                  class="form-control resume_input"
                  v-model="resume.city"
                  @input="debouncedUpdateResume"
                />
              </div>
            </div>
            <div
              class="mb-3 hidden_input date_birth date_birthField"
              :class="{
                hidden: !resume.dateOfBirth,
                show: resume.dateOfBirth,
              }"
            >
              <div class="hidden_input_wrappr">
                <label
                  for="date-birth"
                  class="form-label resume_label d-flex"
                >
                  Date of birth
                </label>

                <div class="dropdown">
                  <button
                    class="removeField_Btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu remove_field">
                    <li
                      class="dropdown-item"
                      @click="deleteField('date_birthField')"
                    >
                      <i class="fa fa-trash me-1"></i>
                      Remove field
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="date"
                class="form-control resume_input"
                v-model="resume.dateOfBirth"
                @input="debouncedUpdateResume"
              />
            </div>
            <div
              class="mb-3 hidden_input place_birth place_birthField"
              :class="{
                hidden: !resume.placeOfBirth,
                show: resume.placeOfBirth,
              }"
            >
              <div class="hidden_input_wrappr">
                <label
                  for="date-birth"
                  class="form-label resume_label d-flex"
                >
                  Place of birth
                </label>

                <div class="dropdown">
                  <button
                    class="removeField_Btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu remove_field">
                    <li
                      class="dropdown-item"
                      @click="deleteField('place_birthField')"
                    >
                      <i class="fa fa-trash me-1"></i>
                      Remove field
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.placeOfBirth"
                @input="debouncedUpdateResume"
              />
            </div>
            <div
              class="mb-3 hidden_input driver_license driver_licenseField"
              :class="{
                hidden: !resume.drivingLicense,
                show: resume.drivingLicense,
              }"
            >
              <div class="hidden_input_wrappr">
                <label
                  for="date-birth"
                  class="form-label resume_label d-flex"
                >
                  Driver's license
                </label>

                <div class="dropdown">
                  <button
                    class="removeField_Btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu remove_field">
                    <li
                      class="dropdown-item"
                      @click="deleteField('driver_licenseField')"
                    >
                      <i class="fa fa-trash me-1"></i>
                      Remove field
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.drivingLicense"
                @input="debouncedUpdateResume"
              />
            </div>
            <div
              class="mb-3 hidden_input gender_btn gender_btnField"
              :class="{ hidden: !resume.gender, show: resume.gender }"
            >
              <div class="hidden_input_wrappr">
                <label
                  for="date-birth"
                  class="form-label resume_label d-flex"
                >
                  Gender
                </label>

                <div class="dropdown">
                  <button
                    class="removeField_Btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu remove_field">
                    <li
                      class="dropdown-item"
                      @click="deleteField('gender_btnField')"
                    >
                      <i class="fa fa-trash me-1"></i>
                      Remove field
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.gender"
                @input="debouncedUpdateResume"
              />
            </div>
            <div
              class="mb-3 hidden_input nationality_btn nationality_btnField"
              :class="{
                hidden: !resume.nationality,
                show: resume.nationality,
              }"
            >
              <div class="hidden_input_wrappr">
                <label
                  for="date-birth"
                  class="form-label resume_label d-flex"
                >
                  Nationality
                </label>

                <div class="dropdown">
                  <button
                    class="removeField_Btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu remove_field">
                    <li
                      class="dropdown-item"
                      @click="deleteField('nationality_btnField')"
                    >
                      <i class="fa fa-trash me-1"></i>
                      Remove field
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.nationality"
                @input="debouncedUpdateResume"
              />
            </div>
            <div
              class="mb-3 hidden_input civil_btn civil_btnField"
              :class="{ hidden: !resume.civilState, show: resume.civilState }"
            >
              <div class="hidden_input_wrappr">
                <label
                  for="date-birth"
                  class="form-label resume_label d-flex"
                >
                  Civil status
                </label>

                <div class="dropdown">
                  <button
                    class="removeField_Btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu remove_field">
                    <li
                      class="dropdown-item"
                      @click="deleteField('civil_btnField')"
                    >
                      <i class="fa fa-trash me-1"></i>
                      Remove field
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.civilState"
                @input="debouncedUpdateResume"
              />
            </div>
            <div
              class="mb-3 hidden_input website_btn website_btnField"
              :class="{ hidden: !resume.website, show: resume.website }"
            >
              <div class="hidden_input_wrappr">
                <label
                  for="date-birth"
                  class="form-label resume_label d-flex"
                >
                  Website
                </label>

                <div class="dropdown">
                  <button
                    class="removeField_Btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu remove_field">
                    <li
                      class="dropdown-item"
                      @click="deleteField('website_btnField')"
                    >
                      <i class="fa fa-trash me-1"></i>
                      Remove field
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.website"
                @input="debouncedUpdateResume"
              />
            </div>
            <div
              class="mb-3 hidden_input linkedin_btn linkedin_btnField"
              :class="{ hidden: !resume.linkedin, show: resume.linkedin }"
            >
              <div class="hidden_input_wrappr">
                <label
                  for="date-birth"
                  class="form-label resume_label d-flex"
                >
                  Linkedin
                </label>

                <div class="dropdown">
                  <button
                    class="removeField_Btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-vertical"></i>
                  </button>
                  <ul class="dropdown-menu remove_field">
                    <li
                      class="dropdown-item"
                      @click="deleteField('linkedin_btnField')"
                    >
                      <i class="fa fa-trash me-1"></i>
                      Remove field
                    </li>
                  </ul>
                </div>
              </div>
              <input
                type="text"
                class="form-control resume_input"
                v-model="resume.linkedin"
                @input="debouncedUpdateResume"
              />
            </div>

            <div class="form_widget">
              <button
                type="button"
                class="date_birth date_birthField"
                @click="toggleFieldBtn('date_birth')"
                :class="{
                  'd-block': !resume.dateOfBirth,
                  'd-none': resume.dateOfBirth,
                }"
              >
                + Date of birth
              </button>
              <button
                type="button"
                class="place_birth place_birthField"
                @click="toggleFieldBtn('place_birth')"
                :class="{
                  'd-block': !resume.placeOfBirth,
                  'd-none': resume.placeOfBirth,
                }"
              >
                + Place of birth
              </button>
              <button
                type="button"
                class="driver_license driver_licenseField"
                @click="toggleFieldBtn('driver_license')"
                :class="{
                  'd-block': !resume.drivingLicense,
                  'd-none': resume.drivingLicense,
                }"
              >
                + Driver's license
              </button>
              <button
                type="button"
                class="gender_btn gender_btnField"
                @click="toggleFieldBtn('gender_btn')"
                :class="{
                  'd-block': !resume.gender,
                  'd-none': resume.gender,
                }"
              >
                + Gender
              </button>
              <button
                type="button"
                class="nationality_btn nationality_btnField"
                @click="toggleFieldBtn('nationality_btn')"
                :class="{
                  'd-block': !resume.nationality,
                  'd-none': resume.nationality,
                }"
              >
                + Nationality
              </button>
              <button
                type="button"
                class="civil_btn civil_btnField"
                @click="toggleFieldBtn('civil_btn')"
                :class="{
                  'd-block': !resume.civilState,
                  'd-none': resume.civilState,
                }"
              >
                + Civil status
              </button>
              <button
                type="button"
                class="website_btn website_btnField"
                @click="toggleFieldBtn('website_btn')"
                :class="{
                  'd-block': !resume.website,
                  'd-none': resume.website,
                }"
              >
                + Website
              </button>
              <button
                type="button"
                class="linkedin_btn linkedin_btnField"
                @click="toggleFieldBtn('linkedin_btn')"
                :class="{
                  'd-block': !resume.linkedin,
                  'd-none': resume.linkedin,
                }"
              >
                + Linkedin
              </button>
            </div>
          </div>
        </form>
      </div>

      <!-- profile -->

      <div
        class="accordion_wrapper hidden_fields profile_Field"
        ref="formWrapper"
        :class="{
          hidden: !resume.profiles.some((pro) => pro.description),
          show: resume.profiles.some((pro) => pro.description),
        }"
      >
        <form class="resume_form" enctype="multipart/form-data">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Profile"
                class="form-control resume_input header_input"
                v-model="resume.profileDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn toggle_btn_collapse collapsed btn"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personProfile"
                aria-expanded="false"
                aria-controls="personProfile"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personProfile" class="collapse">
            <div class="mt-3">
              <div
                v-for="(profile, index) in resume.profiles"
                :key="index"
                :class="{
                  closeForms: !profile.active,
                  openForm: profile.active,
                }"
                class="formDiv"
              >
                <div class="educationWra resume_formBorder mb-2">
                  <div
                    class="form_openFiedls d-none"
                    @click="toggleForm(index)"
                  >
                    <div class="w-100 d-flex justify-content-sm-between">
                      <div
                        style="
                          overflow: hidden;
                          white-space: nowrap;
                          width: calc(100% - 60px);
                          text-overflow: ellipsis;
                        "
                      >
                        {{
                          profile.description
                            ? profile.description
                            : "[Description]"
                        }}
                      </div>
                      <button type="button">
                        <i class="fa fa-pencil"></i>
                      </button>
                    </div>
                  </div>
                  <div class="form_fields">
                    <div>
                      <label class="form-label resume_label"
                        >Description</label
                      >
                      <textarea
                        class="form-control resume_input"
                        cols="30"
                        rows="5"
                        style="resize: none"
                        v-model="profile.description"
                        @input="debouncedUpdateResume"
                      ></textarea>
                    </div>
                  </div>

                  <div
                    v-if="index > 0"
                    class="form_btn mt-3 d-flex justify-content-end gap-2"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteProfile(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <button class="add_btn" type="button" @click="addProfile">
                + Add Profile
              </button>
            </div>
          </div>
        </form>
      </div>

      <!-- education -->
      <div class="accordion_wrapper" ref="formWrapperEdu">
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Education"
                class="form-control resume_input header_input"
                v-model="resume.educationDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personEducation"
                aria-expanded="false"
                aria-controls="personalDetails"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personEducation" class="collapse">
            <div
              v-for="(education, index) in resume.educations"
              :key="index"
              :class="{
                closeForms: !education.active,
                openForm: education.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{
                        education.education
                          ? education.education
                          : "[Education]"
                      }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="education" class="form-label resume_label"
                      >Education</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="education.education"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                  <div class="d-flex mb-3">
                    <div class="w-50 me-2">
                      <label class="form-label resume_label">School</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="education.school"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="w-50">
                      <label class="form-label resume_label">City</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="education.city"
                        @input="debouncedUpdateResume"
                      />
                      <input type="hidden" v-model="education.person_id" />
                    </div>
                  </div>
                  <div class="d-flex gap-2">
                    <div class="w-50 mb-3">
                      <label class="form-label resume_label"
                        >Start Date</label
                      >
                      <div class="d-flex gap-2">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="education.startMonth"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>

                        <select
                          class="form-select resume_input"
                          v-model="education.startYear"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="w-50 mb-3">
                      <label class="form-label resume_label">End Date</label>
                      <div class="d-flex gap-2">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="education.endMonth"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>

                        <select
                          class="form-select resume_input"
                          v-model="education.endYear"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="form-label resume_label">Description</label>
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="education.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
                <div v-if="index > 0" class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteEducation(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button class="add_btn" type="button" @click="addEducation">
              + Add Education
            </button>
          </div>
        </form>
      </div>

      <!-- employment -->

      <div class="accordion_wrapper" ref="formWrapperEmp">
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Employment"
                class="form-control resume_input header_input"
                v-model="resume.employmentDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personEmployment"
                aria-expanded="false"
                aria-controls="personEmployment"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personEmployment" class="collapse">
            <div
              v-for="(employment, index) in resume.employments"
              :key="index"
              :class="{
                closeForms: !employment.active,
                openForm: employment.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{
                        employment.position
                          ? employment.position
                          : "[Employment]"
                      }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="position" class="form-label resume_label"
                      >Position</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="employment.position"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                  <div class="d-flex mb-3">
                    <div class="w-50 me-2">
                      <label class="form-label resume_label">Employer</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="employment.employer"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="w-50">
                      <label class="form-label resume_label">City</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="employment.city"
                        @input="debouncedUpdateResume"
                      />
                      <input type="hidden" v-model="employment.person_id" />
                    </div>
                  </div>
                  <div class="d-flex gap-2">
                    <div class="w-50 mb-3">
                      <label class="form-label resume_label"
                        >Start Date</label
                      >
                      <div class="d-flex gap-2">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="employment.startMonth"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>

                        <select
                          class="form-select resume_input"
                          v-model="employment.startYear"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="w-50 mb-3">
                      <label class="form-label resume_label">End Date</label>
                      <div class="d-flex gap-2">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="employment.endMonth"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>

                        <select
                          class="form-select resume_input"
                          v-model="employment.endYear"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="form-label resume_label">Description</label>
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="employment.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
                <div v-if="index > 0" class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteEmploymnet(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button class="add_btn" type="button" @click="addEmployer">
              + Add Employment
            </button>
          </div>
        </form>
      </div>

      <!-- skill -->

      <div class="accordion_wrapper" ref="formWrapperSkl">
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Skill"
                class="form-control resume_input header_input"
                v-model="resume.skillDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personSkill"
                aria-expanded="false"
                aria-controls="personSkill"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personSkill" class="collapse">
            <div
              v-for="(skill, index) in resume.skills"
              :key="index"
              :class="{
                closeForms: !skill.active,
                openForm: skill.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{ skill.skill ? skill.skill : "[Skill]" }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="skill" class="form-label resume_label"
                      >Skill</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="skill.skill"
                      @input="debouncedUpdateResume"
                    />

                    <input type="hidden" v-model="skill.person_id" />
                  </div>
                  <div class="mb-3">
                    <label for="level" class="form-label resume_label"
                      >Level</label
                    >
                    <div class="range_div">
                      <input
                        type="range"
                        min="0"
                        max="5"
                        class="form-range"
                        step="1"
                        v-model="skill.skillLevel"
                        @input="debouncedUpdateResume"
                      />
                      <p>{{ getSkillLevelText(skill.skillLevel) }}</p>
                    </div>
                  </div>
                </div>
                <div v-if="index > 0" class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteSkill(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button class="add_btn" type="button" @click="addSkill">
              + Add Skill
            </button>
          </div>
        </form>
      </div>

      <!-- language -->

      <div class="accordion_wrapper" ref="formWrapperLang">
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Languages"
                class="form-control resume_input header_input"
                v-model="resume.languageDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personLanguage"
                aria-expanded="false"
                aria-controls="personLanguage"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personLanguage" class="collapse">
            <div
              v-for="(language, index) in resume.languages"
              :key="index"
              :class="{
                closeForms: !language.active,
                openForm: language.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{
                        language.language ? language.language : "[Language]"
                      }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label class="form-label resume_label">Language</label>
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="language.language"
                      @input="debouncedUpdateResume"
                    />
                    <input type="hidden" v-model="language.person_id" />
                  </div>
                  <div class="mb-3">
                    <label for="level" class="form-label resume_label"
                      >Level</label
                    >
                    <div class="range_div">
                      <input
                        type="range"
                        min="0"
                        max="5"
                        class="form-range"
                        step="1"
                        v-model="language.languageLevel"
                        @input="debouncedUpdateResume"
                      />
                      <p>
                        {{ getLanguageLevelText(language.languageLevel) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="index > 0" class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteLanguage(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button class="add_btn" type="button" @click="addLanguage">
              + Add Language
            </button>
          </div>
        </form>
      </div>

      <!-- hobby -->

      <div class="accordion_wrapper" ref="formWrapperHy">
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Hobbies"
                class="form-control resume_input header_input"
                v-model="resume.hobbiesDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personHobby"
                aria-expanded="false"
                aria-controls="personHobby"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personHobby" class="collapse">
            <div
              v-for="(hobby, index) in resume.hobbies"
              :key="index"
              :class="{
                closeForms: !hobby.active,
                openForm: hobby.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{ hobby.hobby ? hobby.hobby : "[Hobby]" }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="skill" class="form-label resume_label"
                      >Hobby</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="hobby.hobby"
                      @input="debouncedUpdateResume"
                    />
                    <input type="hidden" v-model="hobby.person_id" />
                  </div>
                </div>
                <div v-if="index > 0" class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteHobby(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button class="add_btn" type="button" @click="addHobby">
              + Add Hobby
            </button>
          </div>
        </form>
      </div>

      <!-- courses -->

      <div class="accordion_wrapper" ref="formWrapperCor">
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Courses"
                class="form-control resume_input header_input"
                v-model="resume.coursesDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personCourse"
                aria-expanded="false"
                aria-controls="personCourse"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personCourse" class="collapse">
            <div
              v-for="(course, index) in resume.courses"
              :key="index"
              :class="{
                closeForms: !course.active,
                openForm: course.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{ course.course ? course.course : "[Course]" }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="address" class="form-label resume_label"
                      >Course</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="course.course"
                      @input="debouncedUpdateResume"
                    />
                    <input type="hidden" v-model="course.person_id" />
                  </div>

                  <div>
                    <label class="form-label resume_label">Period</label>
                    <div class="d-flex gap-3 w-100 mb-3">
                      <select
                        class="form-select resume_input resume_select"
                        v-model="course.period_month"
                        @change="debouncedUpdateResume"
                      >
                        <option selected value="">Month</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                      </select>
                      <select
                        class="form-select resume_input"
                        v-model="course.period_year"
                        @change="debouncedUpdateResume"
                      >
                        <option selected value="">Year</option>
                        <option
                          v-for="year in years"
                          :key="year"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label class="form-label resume_label">Description</label>
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="course.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>

                <div
                  v-if="index > 0"
                  class="form_btn mt-3 d-flex justify-content-end gap-2"
                >
                  <button
                    type="button"
                    class="btn btn-outline-secondary delete_btn"
                    @click="deleteCourse(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                  <button type="button" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
            <button class="add_btn" type="button" @click="addCourse">
              + Add Course
            </button>
          </div>
        </form>
      </div>

      <!-- internship -->

      <div
        class="accordion_wrapper hidden_fields internship_Field"
        ref="formWrapperInt"
        :class="{
          show: resume.internships.some(
            (int) =>
              int.position ||
              int.employer ||
              int.city ||
              int.startMonth ||
              int.startYear ||
              int.endMonth ||
              int.endYear ||
              int.description
          ),
          hidden: !resume.internships.some(
            (int) =>
              int.position ||
              int.employer ||
              int.city ||
              int.startMonth ||
              int.startYear ||
              int.endMonth ||
              int.endYear ||
              int.description
          ),
        }"
      >
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Internships"
                class="form-control resume_input header_input"
                v-model="resume.internshipDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn toggle_btn_collapse collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personInternship"
                aria-expanded="false"
                aria-controls="personInternship"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personInternship" class="collapse">
            <div
              v-for="(internship, index) in resume.internships"
              :key="index"
              :class="{
                closeForms: !internship.active,
                openForm: internship.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{
                        internship.position
                          ? internship.position
                          : "[Internships]"
                      }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="position" class="form-label resume_label"
                      >Position</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="internship.position"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                  <div class="d-flex mb-3">
                    <div class="w-50 me-2">
                      <label class="form-label resume_label">Employer</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="internship.employer"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="w-50">
                      <label class="form-label resume_label">City</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="internship.city"
                        @input="debouncedUpdateResume"
                      />
                      <input type="hidden" v-model="internship.person_id" />
                    </div>
                  </div>
                  <div class="d-flex gap-2">
                    <div class="w-50 mb-3">
                      <label class="form-label resume_label"
                        >Start Date</label
                      >
                      <div class="d-flex gap-2">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="internship.startMonth"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>

                        <select
                          class="form-select resume_input"
                          v-model="internship.startYear"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="w-50 mb-3">
                      <label class="form-label resume_label">End Date</label>
                      <div class="d-flex gap-2">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="internship.endMonth"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>

                        <select
                          class="form-select resume_input"
                          v-model="internship.endYear"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="form-label resume_label">Description</label>
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="internship.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
                <div v-if="index > 0" class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteInternship(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button class="add_btn" type="button" @click="addInternship">
              + Add Internship
            </button>
          </div>
        </form>
      </div>

      <!-- activity -->

      <div
        class="accordion_wrapper hidden_fields activity_Field"
        ref="formWrapperAct"
        :class="{
          show: resume.activities.some(
            (act) =>
              act.position ||
              act.employer ||
              act.city ||
              act.startMonth ||
              act.startYear ||
              act.endMonth ||
              act.endYear ||
              act.description
          ),
          hidden: !resume.activities.some(
            (act) =>
              act.position ||
              act.employer ||
              act.city ||
              act.startMonth ||
              act.startYear ||
              act.endMonth ||
              act.endYear ||
              act.description
          ),
        }"
      >
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Activities"
                class="form-control resume_input header_input"
                v-model="resume.extra_activitieDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn toggle_btn_collapse collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personActivity"
                aria-expanded="false"
                aria-controls="personActivity"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personActivity" class="collapse">
            <div
              v-for="(activity, index) in resume.activities"
              :key="index"
              :class="{
                closeForms: !activity.active,
                openForm: activity.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{
                        activity.position ? activity.position : "[Activity]"
                      }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="position" class="form-label resume_label"
                      >Position</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="activity.position"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                  <div class="d-flex mb-3">
                    <div class="w-50 me-2">
                      <label class="form-label resume_label">Employer</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="activity.employer"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="w-50">
                      <label class="form-label resume_label">City</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="activity.city"
                        @input="debouncedUpdateResume"
                      />
                      <input type="hidden" v-model="activity.person_id" />
                    </div>
                  </div>
                  <div class="d-flex gap-2">
                    <div class="w-50 mb-3">
                      <label class="form-label resume_label"
                        >Start Date</label
                      >
                      <div class="d-flex gap-2">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="activity.startMonth"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>

                        <select
                          class="form-select resume_input"
                          v-model="activity.startYear"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="w-50 mb-3">
                      <label class="form-label resume_label">End Date</label>
                      <div class="d-flex gap-2">
                        <select
                          class="form-select resume_input resume_select"
                          v-model="activity.endMonth"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Month</option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>

                        <select
                          class="form-select resume_input"
                          v-model="activity.endYear"
                          @change="debouncedUpdateResume"
                        >
                          <option selected value="">Year</option>
                          <option
                            v-for="year in years"
                            :key="year"
                            :value="year"
                          >
                            {{ year }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label class="form-label resume_label">Description</label>
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="activity.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
                <div v-if="index > 0" class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteActivity(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button class="add_btn" type="button" @click="addActivity">
              + Add Activity
            </button>
          </div>
        </form>
      </div>

      <!-- reference -->

      <div
        class="accordion_wrapper hidden_fields reference_Field"
        ref="formWrapperRef"
        :class="{
          show: resume.references.some(
            (ref) =>
              ref.name ||
              ref.organization ||
              ref.city ||
              ref.phone ||
              ref.email
          ),
          hidden: !resume.references.some(
            (ref) =>
              ref.name ||
              ref.organization ||
              ref.city ||
              ref.phone ||
              ref.email
          ),
        }"
      >
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="References"
                class="form-control resume_input header_input"
                v-model="resume.referenceDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn toggle_btn_collapse collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personReference"
                aria-expanded="false"
                aria-controls="personReference"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personReference" class="collapse">
            <div v-if="resume.references.length === 0">
              <p class="m-0">References available upon request.</p>
            </div>
            <div
              v-for="(reference, index) in resume.references"
              :key="index"
              :class="{
                closeForms: !reference.active,
                openForm: reference.active,
              }"
              class="formDiv"
              v-else
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{ reference.name ? reference.name : "[Reference]" }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="position" class="form-label resume_label"
                      >Name</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="reference.name"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                  <div class="d-flex mb-3">
                    <div class="w-50 me-2">
                      <label class="form-label resume_label"
                        >Organization</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="reference.organization"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="w-50">
                      <label class="form-label resume_label">City</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="reference.city"
                        @input="debouncedUpdateResume"
                      />
                      <input type="hidden" v-model="reference.person_id" />
                    </div>
                  </div>
                  <div class="d-flex mb-3">
                    <div class="w-50 me-2">
                      <label class="form-label resume_label"
                        >Phone Number</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="reference.phone"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="w-50">
                      <label class="form-label resume_label"
                        >Email Address</label
                      >
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="reference.email"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                  </div>
                </div>
                <div class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteReference(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button class="add_btn" type="button" @click="addRefecence">
              + Add Reference
            </button>
          </div>
        </form>
      </div>

      <!-- qualities -->

      <div
        class="accordion_wrapper hidden_fields quality_Field"
        ref="formWrapperQual"
        :class="{
          show: resume.qualities.some((qual) => qual.quality),
          hidden: !resume.qualities.some((qual) => qual.quality),
        }"
      >
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Qualities"
                class="form-control resume_input header_input"
                v-model="resume.qualityDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn toggle_btn_collapse collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personQuality"
                aria-expanded="false"
                aria-controls="personQuality"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personQuality" class="collapse">
            <div
              v-for="(quality, index) in resume.qualities"
              :key="index"
              :class="{
                closeForms: !quality.active,
                openForm: quality.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{ quality.quality ? quality.quality : "[Quality]" }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label class="form-label resume_label">Quality</label>
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="quality.quality"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                </div>
                <div class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteQualities(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button class="add_btn" type="button" @click="addQuality">
              + Add Quality
            </button>
          </div>
        </form>
      </div>

      <!-- certificate -->

      <div
        class="accordion_wrapper hidden_fields certificate_Field"
        ref="formWrapperCert"
        :class="{
          show: resume.certificates.some(
            (cert) =>
              cert.certificate ||
              cert.monthPeriod ||
              cert.yearPeriod ||
              cert.description
          ),
          hidden: !resume.certificates.some(
            (cert) =>
              cert.certificate ||
              cert.monthPeriod ||
              cert.yearPeriod ||
              cert.Description
          ),
        }"
      >
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Certificates"
                class="form-control resume_input header_input"
                v-model="resume.certificateDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn toggle_btn_collapse collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personCertificate"
                aria-expanded="false"
                aria-controls="personCertificate"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personCertificate" class="collapse">
            <div
              v-for="(certificate, index) in resume.certificates"
              :key="index"
              :class="{
                closeForms: !certificate.active,
                openForm: certificate.active,
              }"
              class="formDiv"
            >
              <div class="educationWra resume_formBorder mb-2">
                <div
                  class="form_openFiedls d-none"
                  @click="toggleForm(index)"
                >
                  <div class="w-100 d-flex justify-content-sm-between">
                    <div>
                      {{
                        certificate.certificate
                          ? certificate.certificate
                          : "[Certificate]"
                      }}
                    </div>
                    <button type="button">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="form_fields">
                  <div class="mb-3">
                    <label for="position" class="form-label resume_label"
                      >Certificate</label
                    >
                    <input
                      type="text"
                      class="form-control resume_input"
                      v-model="certificate.certificate"
                      @input="debouncedUpdateResume"
                    />
                  </div>
                  <div>
                    <label class="form-label resume_label">Period</label>
                    <div class="d-flex gap-3 w-100 mb-3">
                      <select
                        class="form-select resume_input resume_select"
                        v-model="certificate.monthPeriod"
                        @change="debouncedUpdateResume"
                      >
                        <option selected value="">Month</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                      </select>
                      <select
                        class="form-select resume_input"
                        v-model="certificate.yearPeriod"
                        @change="debouncedUpdateResume"
                      >
                        <option selected value="">Year</option>
                        <option
                          v-for="year in years"
                          :key="year"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label class="form-label resume_label">Description</label>
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="certificate.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
                <div class="form_btn mt-3">
                  <div class="w-100 d-flex justify-content-end gap-2">
                    <button
                      type="button"
                      class="btn btn-outline-secondary delete_btn"
                      @click="deleteCertificate(index)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                    <button type="button" class="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button class="add_btn" type="button" @click="addCertificate">
              + Add Certificate
            </button>
          </div>
        </form>
      </div>

      <!-- achievements -->

      <div
        class="accordion_wrapper hidden_fields achievement_Field"
        ref="formWrapperAch"
        :class="{
          show: resume.achievement.description,
          hidden: !resume.achievement.description,
        }"
      >
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Achievements"
                class="form-control resume_input header_input"
                v-model="resume.achievementDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn toggle_btn_collapse collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personAchievement"
                aria-expanded="false"
                aria-controls="personAchievement"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personAchievement" class="collapse">
            <div class="formDiv">
              <div class="educationWra resume_formBorder mb-2">
                <div class="form_fields">
                  <div class="mb-3">
                    <label class="form-label resume_label">Description</label>
                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="resume.achievement.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!--signature -->

      <div
        class="accordion_wrapper hidden_fields signature_Field"
        ref="formWrapperSign"
        :class="{
          show: resume.signatures.date || resume.signatures.signature,
          hidden: !resume.signatures.date || resume.signatures.signature,
        }"
      >
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Signatures"
                class="form-control resume_input header_input"
                v-model="resume.signatureDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn toggle_btn_collapse collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personSignature"
                aria-expanded="false"
                aria-controls="personSignature"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personSignature" class="collapse">
            <div class="formDiv">
              <div class="educationWra resume_formBorder mb-2">
                <div class="form_fields">
                  <div class="d-flex mb-3">
                    <div class="w-50 me-2">
                      <label class="form-label resume_label">City</label>
                      <input
                        type="text"
                        class="form-control resume_input"
                        v-model="resume.city"
                        @input="debouncedUpdateResume"
                      />
                    </div>
                    <div class="w-50">
                      <label class="form-label resume_label">Date</label>
                      <input
                        type="date"
                        class="form-control resume_input"
                        v-model="resume.signatures.date"
                        @input="debouncedUpdateResume"
                      />

                      <input
                        type="hidden"
                        v-model="resume.signatures.person_id"
                      />
                    </div>
                  </div>

                  <div class="signature_Wrapper">
                    <div>
                      <label class="form-label resume_label">Signature</label>
                      <div class="dropdown deleteDropdown">
                        <button
                          class="btn"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="fa fa-ellipsis-v"></i>
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item"
                              @click="delete_signature"
                              href="#"
                              >Delete</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="signture_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#signtureModal"
                      @click="renderFunction()"
                    >
                      <img
                        v-if="resume.signatures.signature"
                        :src="resume.signatures.signature"
                        alt="signature"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!--signature Modal -->
      <div
        class="modal fade signatureModal"
        id="signtureModal"
        tabindex="-1"
        aria-labelledby="signtureModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="signtureModalLabel">
                Signature
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click="closeModel"
              ></button>
            </div>
            <div class="modal-body">
              <div class="tabsSignature">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-draw-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-draw"
                      type="button"
                      role="tab"
                      aria-controls="pills-draw"
                      aria-selected="true"
                      style="border-right: 1px solid #949494"
                    >
                      Draw
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-type-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-type"
                      type="button"
                      role="tab"
                      aria-controls="pills-type"
                      aria-selected="false"
                      style="border-right: 1px solid #949494"
                    >
                      Type
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-upload-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-upload"
                      type="button"
                      role="tab"
                      aria-controls="pills-upload"
                      aria-selected="false"
                    >
                      Upload
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-draw"
                    role="tabpanel"
                    aria-labelledby="pills-draw-tab"
                    tabindex="0"
                  >
                    <div :key="renderkey" class="signature_fields">
                      <VueSignaturePad
                        id="signature"
                        style="width: 100%; height: 200px"
                        ref="signaturePad"
                        :options="options"
                        @click="handleSignatureChange"
                      />
                      <div class="button_wrapper">
                        <div class="btns">
                          <button @click="changeColor('black')" class="black">
                            <i
                              v-show="options.penColor === 'black'"
                              class="fa fa-check"
                            ></i>
                          </button>
                          <button @click="changeColor('red')" class="red">
                            <i
                              v-show="options.penColor === 'red'"
                              class="fa fa-check"
                            ></i>
                          </button>
                          <button @click="changeColor('blue')" class="blue">
                            <i
                              v-show="options.penColor === 'blue'"
                              class="fa fa-check"
                            ></i>
                          </button>

                          <p
                            id="clear"
                            class="d-none"
                            @click="handleClearSignautre"
                          >
                            <i class="fa fa-eraser"></i>
                            Erase
                          </p>
                        </div>
                        <div>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            class="btn done"
                            @click="save"
                            id="done"
                            :class="{ 'empty-canvas': isCanvasEmpty }"
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-type"
                    role="tabpanel"
                    aria-labelledby="pills-type-tab"
                    tabindex="0"
                  >
                    <div class="signature_fields">
                      <input
                        type="text"
                        id="signatureInput"
                        :class="{
                          black: options.penColor === 'black',
                          red: options.penColor === 'red',
                          blue: options.penColor === 'blue',
                          dancing: signatureFamily === 'dancing',
                          parisienne: signatureFamily === 'parisienne',
                          sacramento: signatureFamily === 'sacramento',
                        }"
                        :value="signatureType"
                        @input="updateSignatureValue"
                      />
                      <div class="button_wrapper">
                        <div class="btns">
                          <button @click="changeColor('black')" class="black">
                            <i
                              v-show="options.penColor === 'black'"
                              class="fa fa-check"
                            ></i>
                          </button>
                          <button @click="changeColor('red')" class="red">
                            <i
                              v-show="options.penColor === 'red'"
                              class="fa fa-check"
                            ></i>
                          </button>
                          <button @click="changeColor('blue')" class="blue">
                            <i
                              v-show="options.penColor === 'blue'"
                              class="fa fa-check"
                            ></i>
                          </button>

                          <div class="dropdown fontDiv">
                            <button
                              class="btn dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Aa Font
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  @click="changeFamily('dancing')"
                                  :class="{
                                    active: signatureFamily === 'dancing',
                                  }"
                                  class="dropdown-item font font-1"
                                  href="#"
                                >
                                  <p>
                                    <i
                                      v-show="signatureFamily === 'dancing'"
                                      class="fa fa-check"
                                    >
                                    </i>
                                  </p>
                                  {{
                                    signatureType
                                      ? signatureType
                                      : "Signature"
                                  }}</a
                                >
                              </li>
                              <li>
                                <a
                                  @click="changeFamily('parisienne')"
                                  :class="{
                                    active: signatureFamily === 'parisienne',
                                  }"
                                  class="dropdown-item font font-2"
                                  href="#"
                                >
                                  <p>
                                    <i
                                      v-show="
                                        signatureFamily === 'parisienne'
                                      "
                                      class="fa fa-check"
                                    >
                                    </i>
                                  </p>

                                  {{
                                    signatureType
                                      ? signatureType
                                      : "Signature"
                                  }}</a
                                >
                              </li>
                              <li>
                                <a
                                  @click="changeFamily('sacramento')"
                                  :class="{
                                    active: signatureFamily === 'sacramento',
                                  }"
                                  class="dropdown-item font font-3"
                                  href="#"
                                >
                                  <p>
                                    <i
                                      v-show="
                                        signatureFamily === 'sacramento'
                                      "
                                      class="fa fa-check"
                                    >
                                    </i>
                                  </p>
                                  {{
                                    signatureType
                                      ? signatureType
                                      : "Signature"
                                  }}</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            class="btn done"
                            @click="save"
                            :class="{ 'empty-canvas': signatureType === '' }"
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-upload"
                    role="tabpanel"
                    aria-labelledby="pills-upload-tab"
                    tabindex="0"
                  >
                    <div class="signature_fields">
                      <div class="upload_image" ref="uploadedImage">
                        <div
                          v-if="!imageUrl"
                          @click="openFileInput"
                          @drop="handleDrop"
                          @dragover.prevent
                        >
                          <p>
                            Drag & drop your image here or click to upload.
                          </p>
                          <input
                            type="file"
                            @change="handleFileInput"
                            accept="image/*"
                            style="display: none"
                            ref="fileInput"
                          />
                          <button>Upload Image</button>
                        </div>
                        <img
                          :class="{
                            black: options.penColor === 'black',
                            red: options.penColor === 'red',
                            blue: options.penColor === 'blue',
                          }"
                          :src="imageUrl"
                          alt="Uploaded Image"
                          v-if="imageUrl"
                          ref="imagefilter"
                        />
                      </div>
                      <div class="button_wrapper">
                        <div class="btns">
                          <button @click="changeColor('black')" class="black">
                            <i
                              v-show="options.penColor === 'black'"
                              class="fa fa-check"
                            ></i>
                          </button>
                          <button @click="changeColor('red')" class="red">
                            <i
                              v-show="options.penColor === 'red'"
                              class="fa fa-check"
                            ></i>
                          </button>
                          <button @click="changeColor('blue')" class="blue">
                            <i
                              v-show="options.penColor === 'blue'"
                              class="fa fa-check"
                            ></i>
                          </button>

                          <p
                            @click="deleteImage"
                            :class="{
                              none: !imageUrl,
                            }"
                          >
                            <i class="fa fa-eraser"></i>
                            Erase
                          </p>
                        </div>
                        <div>
                          <button
                            data-bs-dismiss="modal"
                            type="button"
                            class="btn done"
                            @click="save"
                            :class="{ 'empty-canvas': !imageUrl }"
                          >
                            Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- footer -->

      <div
        class="accordion_wrapper hidden_fields footer_Field"
        ref="formWrapperFot"
        :class="{
          show: resume.footer.description && resume.footer.status === 1,
          hidden: !resume.footer.description || resume.footer.status === 0,
        }"
      >
        <form class="resume_form">
          <div class="accrodion_header">
            <div class="form_header">
              <input
                type="text"
                placeholder="Achievements"
                class="form-control resume_input header_input"
                v-model="resume.footerDetails"
                @input="debouncedUpdateResume"
              />
            </div>
            <div>
              <button
                class="toggle_btn btn toggle_btn_collapse collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#personFooter"
                aria-expanded="false"
                aria-controls="personFooter"
              >
                <i class="fa fa-angle-up"></i>
              </button>
            </div>
          </div>
          <div id="personFooter" class="collapse">
            <div class="formDiv">
              <div class="educationWra resume_formBorder mb-2">
                <div class="form_fields">
                  <div class="mb-3">
                    <label class="form-label resume_label">Description</label>

                    <textarea
                      class="form-control resume_input"
                      cols="30"
                      rows="5"
                      style="resize: none"
                      v-model="resume.footer.description"
                      @input="debouncedUpdateResume"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="field_widgets">
        <button
          type="button"
          class="profile_Field"
          @click="mainFieldBtn('profile_Field'), scrollToTop('formWrapper')"
          :class="{
            hidden: resume.profiles.some((pro) => pro.description),
          }"
        >
          + Profile
        </button>
        <button
          type="button"
          class="internship_Field"
          @click="
            mainFieldBtn('internship_Field'), scrollToTop('formWrapperInt')
          "
          :class="{
            hidden: resume.internships.some(
              (int) =>
                int.position ||
                int.employer ||
                int.city ||
                int.startMonth ||
                int.startYear ||
                int.endMonth ||
                int.endYear ||
                int.description
            ),
          }"
        >
          + Internships
        </button>
        <button
          type="button"
          class="activity_Field"
          @click="
            mainFieldBtn('activity_Field'), scrollToTop('formWrapperAct')
          "
          :class="{
            hidden: resume.activities.some(
              (act) =>
                act.position ||
                act.employer ||
                act.city ||
                act.startMonth ||
                act.startYear ||
                act.endMonth ||
                act.endYear ||
                act.description
            ),
          }"
        >
          + Extracurricular activities
        </button>
        <button
          type="button"
          class="reference_Field"
          @click="
            mainFieldBtn('reference_Field'), scrollToTop('formWrapperRef')
          "
          :class="{
            hidden: resume.references.some(
              (ref) =>
                ref.name ||
                ref.organization ||
                ref.city ||
                ref.phone ||
                ref.email
            ),
          }"
        >
          + References
        </button>
        <button
          type="button"
          class="quality_Field"
          @click="
            mainFieldBtn('quality_Field'), scrollToTop('formWrapperQual')
          "
          :class="{
            hidden: resume.qualities.some((qual) => qual.quality),
          }"
        >
          + Qualities
        </button>
        <button
          type="button"
          class="certificate_Field"
          @click="
            mainFieldBtn('certificate_Field'), scrollToTop('formWrapperCert')
          "
          :class="{
            hidden: resume.certificates.some(
              (cert) =>
                cert.certificate ||
                cert.monthPeriod ||
                cert.yearPeriod ||
                cert.description
            ),
          }"
        >
          + Certificates</button
        ><button
          type="button"
          class="achievement_Field"
          @click="
            mainFieldBtn('achievement_Field'), scrollToTop('formWrapperAch')
          "
          :class="{
            hidden: resume.achievement.description,
          }"
        >
          + Achievements</button
        ><button
          type="button"
          class="signature_Field"
          @click="
            mainFieldBtn('signature_Field'), scrollToTop('formWrapperSign')
          "
          :class="{
            hidden: resume.signatures.date || resume.signatures.signature,
          }"
        >
          + Signature</button
        ><button
          type="button"
          class="footer_Field"
          @click="
            mainFieldBtn('footer_Field'),
              scrollToTop('formWrapperFot'),
              handelShowFooter()
          "
          :class="{
            hidden: resume.footer.description && resume.footer.status === 1,
          }"
        >
          + Footer
        </button>
      </div>

      <div class="download">
        <button type="button" @click="downloadResume">Download</button>
      </div>
    </div>
    <div class="resumePages" >
      <div ref="resumePage" class="form-wrapper" @click="scaleFunction" id="personalForm">
          <div  class="personalFormEmpty"  >
            <div class="personalForm_header">
              <div class="person_img" v-if="resume.profileImage">
                <img :src="resume.profileImage" alt="img" />
              </div>
              <div
                class="person_content"
                :class="{ person_fullContnet: resume.profileImage }"
              >
                <p
                  class="person_fullName"
                  v-if="resume.name || resume.familyName"
                  :class="{ longText: fullNameWordLength >= 70 }"
                >
                  <span v-if="resume.name" :style="{ fontSize: FontSize }">{{
                    resume.name
                  }}</span>
                  <span
                    v-if="resume.familyName"
                    :class="{ 'ms-1': resume.name && resume.familyName }"
                    :style="{ fontSize: FontSize }"
                  >
                    {{ resume.familyName }}
                  </span>
                </p>

                <div v-if="resume.headline" class="person_headline">
                  <p>{{ resume.headline }}</p>
                </div>
                <div class="person_address">
                  <p v-if="resume.email">
                    <i class="fa fa-envelope"></i>{{ resume.email }}
                  </p>
                  <p v-if="resume.phoneNumber">
                    <i class="fa fa-phone"></i>{{ resume.phoneNumber }}
                  </p>
                  <p v-if="resume.address || resume.city || resume.postCode">
                    <i class="fa fa-location-dot" v-if="resume.address"></i
                    >{{ resume.address }}
                    <span
                      style="margin-left: -2px"
                      v-if="
                        (resume.address && resume.postCode) ||
                        (resume.address && resume.city)
                      "
                      >,</span
                    >
                    <span v-if="resume.postCode">{{ resume.postCode }}</span>
                    <span v-if="resume.city">{{ resume.city }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="personalForm_body" ref="resumePageHeight">
              <div class="personalForm_main">
                <!-- profile -->
                <div class="section_resume">
                  <h6
                    v-if="
                      resume.profileDetails &&
                      resume.profiles.some((edu) => edu.description)
                    "
                    class="resume_object_heading"
                  >
                    {{ resume.profileDetails }}
                  </h6>
                  <div v-for="(profile, index) in resume.profiles" :key="index">
                    <div v-if="profile.description" class="education_div">
                      <p>{{ profile.description }}</p>
                    </div>
                  </div>
                </div>

                <hr
                  class="my-2"
                  v-if="
                    resume.profiles.some((prof) => prof.description) &&
                    (resume.educations.some(
                      (edu) =>
                        edu.education ||
                        edu.school ||
                        edu.city ||
                        edu.startMonth ||
                        edu.startYear ||
                        edu.endMonth ||
                        edu.endYear ||
                        edu.description
                    ) ||
                      resume.employments.some(
                        (epm) =>
                          epm.position ||
                          epm.employer ||
                          epm.city ||
                          epm.startMonth ||
                          epm.startYear ||
                          epm.endMonth ||
                          epm.endYear ||
                          epm.description
                      ) ||
                      resume.courses.some(
                        (crs) =>
                          crs.course ||
                          crs.period_month ||
                          crs.period_year ||
                          crs.description
                      ) ||
                      resume.internships.some(
                        (int) =>
                          int.position ||
                          int.employer ||
                          int.city ||
                          int.startMonth ||
                          int.startYear ||
                          int.endMonth ||
                          int.endYear ||
                          int.description
                      ) ||
                      resume.activities.some(
                        (act) =>
                          act.position ||
                          act.employer ||
                          act.city ||
                          act.startMonth ||
                          act.startYear ||
                          act.endMonth ||
                          act.endYear ||
                          act.description
                      ) ||
                      resume.references.some(
                        (ref) =>
                          ref.name ||
                          ref.organization ||
                          ref.city ||
                          ref.phone ||
                          ref.email
                      ) ||
                      resume.certificates.some(
                        (cet) =>
                          cet.certificate ||
                          cet.monthPeriod ||
                          cet.yearPeriod ||
                          cet.description
                      ) ||
                      resume.achievement.description ||
                      resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                />
                <!-- education -->

                <div class="section_resume">
                  <h6
                    v-if="
                      resume.educationDetails &&
                      resume.educations.some(
                        (edu) =>
                          edu.education ||
                          edu.school ||
                          edu.city ||
                          edu.startMonth ||
                          edu.startYear ||
                          edu.endMonth ||
                          edu.endYear ||
                          edu.description
                      )
                    "
                    class="resume_object_heading"
                  >
                    {{ resume.educationDetails }}
                  </h6>
                  <div
                    v-for="(education, index) in resume.educations"
                    :key="index"
                  >
                    <div
                      v-if="
                        education.education ||
                        education.school ||
                        education.city ||
                        education.startMonth ||
                        education.startYear ||
                        education.endMonth ||
                        education.endYear ||
                        education.description
                      "
                      class="education_div"
                    >
                      <div class="education_date">
                        <div class="edu_school_wrapper">
                          <h5>{{ education.education }}</h5>
                          <div class="edu_school">
                            <p>
                              {{ education.school }}
                              <span v-if="education.school && education.city"
                                >,</span
                              >
                            </p>
                            <p>{{ education.city }}</p>
                          </div>
                        </div>
                        <div class="edu_date_time">
                          <p>
                            {{ education.startMonth }}
                          </p>
                          <p>{{ education.startYear }}</p>
                          <span
                            v-if="
                              (education.startMonth || education.startYear) &&
                              (education.endMonth || education.endYear)
                            "
                            >-</span
                          >
                          <p>
                            {{ education.endMonth }}
                          </p>
                          <p>{{ education.endYear }}</p>
                        </div>
                      </div>
                      <p>{{ education.description }}</p>
                    </div>
                  </div>
                </div>

                <hr
                  class="my-2"
                  v-if="
                    resume.educations.some(
                      (edu) =>
                        edu.education ||
                        edu.school ||
                        edu.city ||
                        edu.startMonth ||
                        edu.startYear ||
                        edu.endMonth ||
                        edu.endYear ||
                        edu.description
                    )&&
                    ( resume.employments.some(
                        (epm) =>
                          epm.position ||
                          epm.employer ||
                          epm.city ||
                          epm.startMonth ||
                          epm.startYear ||
                          epm.endMonth ||
                          epm.endYear ||
                          epm.description
                      ) ||
                      resume.courses.some(
                        (crs) =>
                          crs.course ||
                          crs.period_month ||
                          crs.period_year ||
                          crs.description
                      ) ||
                      resume.internships.some(
                        (int) =>
                          int.position ||
                          int.employer ||
                          int.city ||
                          int.startMonth ||
                          int.startYear ||
                          int.endMonth ||
                          int.endYear ||
                          int.description
                      ) ||
                      resume.activities.some(
                        (act) =>
                          act.position ||
                          act.employer ||
                          act.city ||
                          act.startMonth ||
                          act.startYear ||
                          act.endMonth ||
                          act.endYear ||
                          act.description
                      ) ||
                      resume.references.some(
                        (ref) =>
                          ref.name ||
                          ref.organization ||
                          ref.city ||
                          ref.phone ||
                          ref.email
                      ) ||
                      resume.certificates.some(
                        (cet) =>
                          cet.certificate ||
                          cet.monthPeriod ||
                          cet.yearPeriod ||
                          cet.description
                      ) ||
                      resume.achievement.description ||
                      resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                />

                <!-- employment -->

                <div class="section_resume">
                  <h6
                    v-if="
                      resume.employmentDetails &&
                      resume.employments.some(
                        (epm) =>
                          epm.position ||
                          epm.employer ||
                          epm.city ||
                          epm.startMonth ||
                          epm.startYear ||
                          epm.endMonth ||
                          epm.endYear ||
                          epm.description
                      )
                    "
                    class="resume_object_heading"
                  >
                    {{ resume.employmentDetails }}
                  </h6>
                  <div
                    v-for="(employment, index) in resume.employments"
                    :key="index"
                  >
                    <div
                      v-if="
                        employment.position ||
                        employment.employer ||
                        employment.city ||
                        employment.startMonth ||
                        employment.startYear ||
                        employment.endMonth ||
                        employment.endYear ||
                        employment.description
                      "
                      class="education_div"
                    >
                      <div class="education_date">
                        <div class="edu_school_wrapper">
                          <h5>{{ employment.position }}</h5>
                          <div class="edu_school">
                            <p>
                              {{ employment.employer }}
                              <span v-if="employment.employer && employment.city"
                                >,</span
                              >
                            </p>
                            <p>{{ employment.city }}</p>
                          </div>
                        </div>
                        <div class="edu_date_time">
                          <p>
                            {{ employment.startMonth }}
                          </p>
                          <p>{{ employment.startYear }}</p>
                          <span
                            v-if="
                              (employment.startMonth || employment.startYear) &&
                              (employment.endMonth || employment.endYear)
                            "
                            >-</span
                          >
                          <p>
                            {{ employment.endMonth }}
                          </p>
                          <p>{{ employment.endYear }}</p>
                        </div>
                      </div>
                      <p>{{ employment.description }}</p>
                    </div>
                  </div>
                </div>

                  <hr
                  class="my-2"
                  v-if="
                    resume.employments.some(
                        (epm) =>
                          epm.position ||
                          epm.employer ||
                          epm.city ||
                          epm.startMonth ||
                          epm.startYear ||
                          epm.endMonth ||
                          epm.endYear ||
                          epm.description
                      ) &&
                    (resume.courses.some(
                        (crs) =>
                          crs.course ||
                          crs.period_month ||
                          crs.period_year ||
                          crs.description
                      ) ||
                      resume.internships.some(
                        (int) =>
                          int.position ||
                          int.employer ||
                          int.city ||
                          int.startMonth ||
                          int.startYear ||
                          int.endMonth ||
                          int.endYear ||
                          int.description
                      ) ||
                      resume.activities.some(
                        (act) =>
                          act.position ||
                          act.employer ||
                          act.city ||
                          act.startMonth ||
                          act.startYear ||
                          act.endMonth ||
                          act.endYear ||
                          act.description
                      ) ||
                      resume.references.some(
                        (ref) =>
                          ref.name ||
                          ref.organization ||
                          ref.city ||
                          ref.phone ||
                          ref.email
                      ) ||
                      resume.certificates.some(
                        (cet) =>
                          cet.certificate ||
                          cet.monthPeriod ||
                          cet.yearPeriod ||
                          cet.description
                      ) ||
                      resume.achievement.description ||
                      resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                  />
                  <!-- course -->

                  <div class="section_resume">
                    <h6
                      v-if="
                        resume.coursesDetails &&
                        resume.courses.some(
                          (crs) =>
                            crs.course ||
                            crs.period_month ||
                            crs.period_year ||
                            crs.description
                        )
                      "
                      class="resume_object_heading"
                    >
                      {{ resume.coursesDetails }}
                    </h6>
                    <div v-for="(course, index) in resume.courses" :key="index">
                      <div
                        v-if="
                          course.course ||
                          course.period_month ||
                          course.period_year ||
                          course.description
                        "
                        class="education_div"
                      >
                        <div class="education_date">
                          <div class="edu_school_wrapper">
                            <h5>{{ course.course }}</h5>
                          </div>
                          <div
                            class="edu_date_time"
                            v-if="course.period_month && course.period_year"
                          >
                            <p>
                              {{ course.period_month }}
                            </p>
                            <span v-if="course.period_month && course.period_year"
                              >-</span
                            >
                            <p>{{ course.period_year }}</p>
                          </div>
                        </div>
                        <p>{{ course.description }}</p>
                      </div>
                    </div>
                  </div>

                  <hr
                  class="my-2"
                  v-if="
                    resume.courses.some(
                        (crs) =>
                          crs.course ||
                          crs.period_month ||
                          crs.period_year ||
                          crs.description
                      ) &&
                    ( resume.internships.some(
                        (int) =>
                          int.position ||
                          int.employer ||
                          int.city ||
                          int.startMonth ||
                          int.startYear ||
                          int.endMonth ||
                          int.endYear ||
                          int.description
                      ) ||
                      resume.activities.some(
                        (act) =>
                          act.position ||
                          act.employer ||
                          act.city ||
                          act.startMonth ||
                          act.startYear ||
                          act.endMonth ||
                          act.endYear ||
                          act.description
                      ) ||
                      resume.references.some(
                        (ref) =>
                          ref.name ||
                          ref.organization ||
                          ref.city ||
                          ref.phone ||
                          ref.email
                      ) ||
                      resume.certificates.some(
                        (cet) =>
                          cet.certificate ||
                          cet.monthPeriod ||
                          cet.yearPeriod ||
                          cet.description
                      ) ||
                      resume.achievement.description ||
                      resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                  />

                  <!-- internship -->

                  <div class="section_resume">
                    <h6
                      v-if="
                        resume.internshipDetails &&
                        resume.internships.some(
                          (int) =>
                            int.position ||
                            int.employer ||
                            int.city ||
                            int.startMonth ||
                            int.startYear ||
                            int.endMonth ||
                            int.endYear ||
                            int.description
                        )
                      "
                      class="resume_object_heading"
                    >
                      {{ resume.internshipDetails }}
                    </h6>
                    <div
                      v-for="(internship, index) in resume.internships"
                      :key="index"
                    >
                      <div
                        v-if="
                          internship.position ||
                          internship.employer ||
                          internship.city ||
                          internship.startMonth ||
                          internship.startYear ||
                          internship.endMonth ||
                          internship.endYear ||
                          internship.description
                        "
                        class="education_div"
                      >
                        <div class="education_date">
                          <div class="edu_school_wrapper">
                            <h5>{{ internship.position }}</h5>
                            <div class="edu_school">
                              <p>
                                {{ internship.employer }}
                                <span
                                  v-if="internship.employer && internship.city"
                                  >,</span
                                >
                              </p>
                              <p>{{ internship.city }}</p>
                            </div>
                          </div>
                          <div class="edu_date_time">
                            <p>
                              {{ internship.startMonth }}
                            </p>
                            <p>{{ internship.startYear }}</p>
                            <span
                              v-if="
                                (internship.startMonth || internship.startYear) &&
                                (internship.endMonth || internship.endYear)
                              "
                              >-</span
                            >
                            <p>
                              {{ internship.endMonth }}
                            </p>
                            <p>{{ internship.endYear }}</p>
                          </div>
                        </div>
                        <p>{{ internship.description }}</p>
                      </div>
                    </div>
                  </div>

                  <hr
                  class="my-2"
                  v-if="
                  resume.internships.some(
                        (int) =>
                          int.position ||
                          int.employer ||
                          int.city ||
                          int.startMonth ||
                          int.startYear ||
                          int.endMonth ||
                          int.endYear ||
                          int.description
                      ) &&
                    ( resume.activities.some(
                        (act) =>
                          act.position ||
                          act.employer ||
                          act.city ||
                          act.startMonth ||
                          act.startYear ||
                          act.endMonth ||
                          act.endYear ||
                          act.description
                      ) ||
                      resume.references.some(
                        (ref) =>
                          ref.name ||
                          ref.organization ||
                          ref.city ||
                          ref.phone ||
                          ref.email
                      ) ||
                      resume.certificates.some(
                        (cet) =>
                          cet.certificate ||
                          cet.monthPeriod ||
                          cet.yearPeriod ||
                          cet.description
                      ) ||
                      resume.achievement.description ||
                      resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                  />
                  <!-- activity -->

                  <div class="section_resume">
                    <h6
                      v-if="
                        resume.extra_activitieDetails &&
                        resume.activities.some(
                          (act) =>
                            act.position ||
                            act.employer ||
                            act.city ||
                            act.startMonth ||
                            act.startYear ||
                            act.endMonth ||
                            act.endYear ||
                            act.description
                        )
                      "
                      class="resume_object_heading"
                    >
                      {{ resume.extra_activitieDetails }}
                    </h6>
                    <div
                      v-for="(activity, index) in resume.activities"
                      :key="index"
                    >
                      <div
                        v-if="
                          activity.position ||
                          activity.employer ||
                          activity.city ||
                          activity.startMonth ||
                          activity.startYear ||
                          activity.endMonth ||
                          activity.endYear ||
                          activity.description
                        "
                        class="education_div"
                      >
                        <div class="education_date">
                          <div class="edu_school_wrapper">
                            <h5>{{ activity.position }}</h5>
                            <div class="edu_school">
                              <p>
                                {{ activity.employer }}
                                <span v-if="activity.employer && activity.city"
                                  >,</span
                                >
                              </p>
                              <p>{{ activity.city }}</p>
                            </div>
                          </div>
                          <div class="edu_date_time">
                            <p>
                              {{ activity.startMonth }}
                            </p>
                            <p>{{ activity.startYear }}</p>
                            <span
                              v-if="
                                (activity.startMonth || activity.startYear) &&
                                (activity.endMonth || activity.endYear)
                              "
                              >-</span
                            >
                            <p>
                              {{ activity.endMonth }}
                            </p>
                            <p>{{ activity.endYear }}</p>
                          </div>
                        </div>
                        <p>{{ activity.description }}</p>
                      </div>
                    </div>
                  </div>

                  <hr
                  class="my-2"
                  v-if="
                    resume.activities.some(
                        (act) =>
                          act.position ||
                          act.employer ||
                          act.city ||
                          act.startMonth ||
                          act.startYear ||
                          act.endMonth ||
                          act.endYear ||
                          act.description
                      ) &&
                    ( resume.references.some(
                        (ref) =>
                          ref.name ||
                          ref.organization ||
                          ref.city ||
                          ref.phone ||
                          ref.email
                      ) ||
                      resume.certificates.some(
                        (cet) =>
                          cet.certificate ||
                          cet.monthPeriod ||
                          cet.yearPeriod ||
                          cet.description
                      ) ||
                      resume.achievement.description ||
                      resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                  />

                  <!-- reference -->

                  <div class="section_resume" id="referene">
                    <h6
                      v-if="
                        resume.referenceDetails &&
                        resume.references.some(
                          (ref) =>
                            ref.name ||
                            ref.organization ||
                            ref.city ||
                            ref.phone ||
                            ref.email
                        )
                      "
                      class="resume_object_heading"
                    >
                      {{ resume.referenceDetails }}
                    </h6>
                    <div
                      v-for="(reference, index) in resume.references"
                      :key="index"
                    >
                      <div
                        v-if="
                          reference.name ||
                          reference.organization ||
                          reference.city ||
                          reference.phone ||
                          reference.email
                        "
                        class="education_div"
                      >
                        <div class="education_date">
                          <div class="edu_school_wrapper">
                            <h5>{{ reference.name }}</h5>
                            <div class="edu_school">
                              <p>
                                {{ reference.organization }}
                                <span
                                  v-if="reference.organization && reference.city"
                                  >,</span
                                >
                              </p>
                              <p>{{ reference.city }}</p>
                            </div>
                            <div class="edu_school">
                              <p style="font-weight: 500">
                                {{ reference.phone }}
                                <span v-if="reference.phone && reference.email"
                                  >,</span
                                >
                              </p>
                              <p style="font-weight: 500">
                                {{ reference.email }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                    <hr
                  class="my-2"
                  v-if="
                  resume.references.some(
                        (ref) =>
                          ref.name ||
                          ref.organization ||
                          ref.city ||
                          ref.phone ||
                          ref.email
                      ) &&
                    ( resume.certificates.some(
                        (cet) =>
                          cet.certificate ||
                          cet.monthPeriod ||
                          cet.yearPeriod ||
                          cet.description
                      ) ||
                      resume.achievement.description ||
                      resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                    />            

                  <!-- certificate -->

                  <div class="section_resume">
                    <h6
                      v-if="
                        resume.certificateDetails &&
                        resume.certificates.some(
                          (cet) =>
                            cet.certificate ||
                            cet.monthPeriod ||
                            cet.yearPeriod ||
                            cet.description
                        )
                      "
                      class="resume_object_heading"
                    >
                      {{ resume.certificateDetails }}
                    </h6>
                    <div
                      v-for="(certificate, index) in resume.certificates"
                      :key="index"
                    >
                      <div
                        v-if="
                          certificate.certificate ||
                          certificate.period_month ||
                          certificate.period_year ||
                          certificate.description
                        "
                        class="education_div"
                      >
                        <div class="education_date">
                          <div class="edu_school_wrapper">
                            <h5>{{ certificate.certificate }}</h5>
                          </div>
                          <div
                            class="edu_date_time"
                            v-if="
                              certificate.monthPeriod && certificate.yearPeriod
                            "
                          >
                            <p>
                              {{ certificate.monthPeriod }}
                            </p>
                            <span
                              v-if="
                                certificate.monthPeriod && certificate.yearPeriod
                              "
                              >-</span
                            >
                            <p>{{ certificate.yearPeriod }}</p>
                          </div>
                        </div>
                        <p>{{ certificate.description }}</p>
                      </div>
                    </div>
                  </div>

                    <hr
                  class="my-2"
                  v-if="
                    resume.certificates.some(
                        (cet) =>
                          cet.certificate ||
                          cet.monthPeriod ||
                          cet.yearPeriod ||
                          cet.description
                      )  &&
                    (
                      resume.achievement.description ||
                      resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                    />   
                  <!-- achievement -->
                  <div class="section_resume">
                    <h6
                      v-if="
                        resume.achievementDetails &&
                        resume.achievement.description
                      "
                      class="resume_object_heading"
                    >
                      {{ resume.achievementDetails }}
                    </h6>
                    <div>
                      <div
                        v-if="resume.achievement.description"
                        class="education_div"
                      >
                        <p>{{ resume.achievement.description }}</p>
                      </div>
                    </div>
                  </div>
                  <hr
                  class="my-2"
                  v-if="
                    resume.achievement.description &&
                    ( resume.signatures.date ||
                      resume.signatures.signature ||
                      (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                  />  

                  <!-- signature -->
                  <div class="section_resume">
                    <div>
                      <div
                        v-if="
                          resume.signatures.date || resume.signatures.signature
                        "
                        class="signature_div"
                      >
                        <div>
                          <p>
                            {{ resume.city }}
                            <span v-if="resume.signatures.date && resume.city"
                              >,</span
                            >
                          </p>
                          <p>{{ resume.signatures.date }}</p>
                        </div>
                        <img
                          v-if="resume.signatures.signature"
                          :src="resume.signatures.signature"
                          alt="signature"
                        />
                      </div>
                    </div>
                  </div>

                  <hr
                  class="my-2"
                  v-if="
                    resume.signatures.date ||
                      resume.signatures.signature  &&
                    ( (resume.footer.description &&
                        resume.footer.status === 1)
                      )
                  "
                  /> 

                  <!-- footer -->
                  <div class="section_resume">
                  
                    <div>
                      <div
                        v-if="
                          resume.footer.description && resume.footer.status === 1
                        "
                        class="education_div"
                      >
                        <p style="font-weight: 300;">{{ resume.footer.description }}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="personalForm_aside">
                <div
                  class="aside_data_wrapper"
                  v-if="
                    resume.dateOfBirth ||
                    resume.placeOfBirth ||
                    resume.drivingLicense ||
                    resume.gender ||
                    resume.nationality ||
                    resume.civilState ||
                    resume.website ||
                    resume.linkedin
                  "
                >
                  <h6
                    v-if="
                      resume.dateOfBirth ||
                      resume.placeOfBirth ||
                      resume.drivingLicense ||
                      resume.gender ||
                      resume.nationality ||
                      resume.civilState ||
                      resume.website ||
                      resume.linkedin
                    "
                  >
                    {{ resume.personalDetails }}
                  </h6>

                  <div class="person_extraInfo" v-if="resume.dateOfBirth">
                    <label class="form-label">Date of birth </label>
                    <p>{{ resume.dateOfBirth }}</p>
                  </div>
                  <div class="person_extraInfo" v-if="resume.placeOfBirth">
                    <label class="form-label">Place of birth</label>
                    <p>{{ resume.placeOfBirth }}</p>
                  </div>
                  <div class="person_extraInfo" v-if="resume.drivingLicense">
                    <label class="form-label">Driver's license</label>
                    <p>{{ resume.drivingLicense }}</p>
                  </div>
                  <div class="person_extraInfo" v-if="resume.gender">
                    <label class="form-label">Gender</label>
                    <p>{{ resume.gender }}</p>
                  </div>
                  <div class="person_extraInfo" v-if="resume.nationality">
                    <label class="form-label">Nationality</label>
                    <p>{{ resume.nationality }}</p>
                  </div>
                  <div class="person_extraInfo" v-if="resume.civilState">
                    <label class="form-label">Civil status</label>
                    <p>{{ resume.civilState }}</p>
                  </div>
                  <div class="person_extraInfo" v-if="resume.website">
                    <label class="form-label">Website</label>
                    <p>{{ resume.website }}</p>
                  </div>
                  <div class="person_extraInfo" v-if="resume.linkedin">
                    <label class="form-label">Linkedin</label>
                    <p>{{ resume.linkedin }}</p>
                  </div>
                </div>
                <!-- skill -->
                <div
                  class="aside_data_wrapper"
                  v-if="
                    resume.skills.some((skl) => skl.skill || skl.skillLevel)
                  "
                >
                  <h6
                    v-if="
                      resume.skillDetails &&
                      resume.skills.some((skl) => skl.skill || skl.skillLevel)
                    "
                    class="resume_object_heading"
                  >
                    {{ resume.skillDetails }}
                  </h6>
                  <div v-for="(skill, index) in resume.skills" :key="index">
                    <div class="aside_data">
                      <h5>{{ skill.skill }}</h5>

                      <div class="progress" v-if="skill.skillLevel > 0">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{
                            width: getSkillLevelPercentage(skill.skillLevel),
                          }"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- language -->
                <div
                  class="aside_data_wrapper"
                  v-if="
                    resume.languages.some(
                      (lang) => lang.language || lang.languageLevel
                    )
                  "
                >
                  <h6
                    v-if="
                      resume.languageDetails &&
                      resume.languages.some(
                        (lang) => lang.language || lang.languageLevel
                      )
                    "
                    class="resume_object_heading"
                  >
                    {{ resume.languageDetails }}
                  </h6>
                  <div
                    v-for="(language, index) in resume.languages"
                    :key="index"
                  >
                    <div class="aside_data">
                      <h5>{{ language.language }}</h5>

                      <div class="progress" v-if="language.languageLevel > 0">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{
                            width: getLanguageLevelPercentage(
                              language.languageLevel
                            ),
                          }"
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- hobby -->

                <div
                  class="aside_data_wrapper"
                  v-if="resume.hobbies.some((hy) => hy.hobby)"
                >
                  <h6
                    v-if="
                      resume.hobbiesDetails &&
                      resume.hobbies.some((hy) => hy.hobby)
                    "
                    class="resume_object_heading"
                  >
                    {{ resume.hobbiesDetails }}
                  </h6>
                  <div v-for="(hobby, index) in resume.hobbies" :key="index">
                    <div class="aside_data">
                      <div class="hobby_list">
                        <p v-if="hobby.hobby"></p>
                        <h6>{{ hobby.hobby }}</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- quality -->

                <div
                  class="aside_data_wrapper"
                  v-if="resume.qualities.some((qual) => qual.quality)"
                >
                  <h6
                    v-if="
                      resume.qualityDetails &&
                      resume.qualities.some((qual) => qual.quality)
                    "
                    class="resume_object_heading"
                  >
                    {{ resume.qualityDetails }}
                  </h6>
                  <div
                    v-for="(quality, index) in resume.qualities"
                    :key="index"
                  >
                    <div class="aside_data">
                      <div class="hobby_list">
                        <p v-if="quality.quality"></p>
                        <h6>{{ quality.quality }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
    </div>
  </div>
</div>
</template>



<script>
import axios from "axios";
import { debounce } from "lodash";
import html2pdf from "html2pdf.js";

export default {
name: "CvMaker-formEdit",
data() {
  return {
    resume: {
      personalDetails: "Person detalis",
      id: "",
      profileImage: null,
      name: "",
      familyName: "",
      email: "",
      headline: "",
      phoneNumber: "",
      address: "",
      postCode: "",
      city: "",
      dateOfBirth: "",
      placeOfBirth: "",
      drivingLicense: "",
      gender: "",
      nationality: "",
      civilState: "",
      website: "",
      linkedin: "",
      educationDetails: "Education",
      employmentDetails: "Employment",
      skillDetails: "Skills",
      languageDetails: "Languages",
      hobbiesDetails: "Hobbies",
      coursesDetails: "Courses",
      profileDetails: "Profile",
      internshipDetails: "Internships",
      extra_activitieDetails: "Extracurricular activities",
      referenceDetails: "References",
      qualityDetails: "Qualities",
      certificateDetails: "Certificates",
      achievementDetails: "Achievements",
      signatureDetails: "Signature",
      footerDetails: "Footer",

      educations: [
        {
          education: "",
          school: "",
          city: "",
          startMonth: "",
          startYear: "",
          endMonth: "",
          endYear: "",
          description: "",
          person_id: "",
        },
      ],
      employments: [
        {
          position: "",
          employer: "",
          city: "",
          startMonth: "",
          startYear: "",
          endMonth: "",
          endYear: "",
          description: "",
          person_id: "",
        },
      ],
      skills: [
        {
          skill: "",
          skillLevel: 0,
          person_id: "",
        },
      ],
      languages: [
        {
          language: "",
          languageLevel: 0,
          person_id: "",
        },
      ],
      hobbies: [
        {
          hobby: "",
          person_id: "",
        },
      ],
      courses: [
        {
          course: "",
          period_month: "",
          period_year: "",
          description: "",
          person_id: "",
        },
      ],
      profiles: [
        {
          description: "",
          person_id: "",
        },
      ],
      internships: [
        {
          position: "",
          employer: "",
          city: "",
          startMonth: "",
          startYear: "",
          endMonth: "",
          endYear: "",
          description: "",
          person_id: "",
        },
      ],
      activities: [
        {
          position: "",
          employer: "",
          city: "",
          startMonth: "",
          startYear: "",
          endMonth: "",
          endYear: "",
          description: "",
          person_id: "",
        },
      ],
      references: [],
      qualities: [
        {
          quality: "",
          person_id: "",
        },
      ],
      certificates: [
        {
          certificate: "",
          monthPeriod: "",
          yearPeriod: "",
          description: "",
          person_id: "",
        },
      ],

      achievement: {
        description: "",
      },
      signatures: {
        date: "",
        signature: "",
      },
      footer: {
        description:
          "I consent to the processing of my personal data for the purpose of recruitment for the position to which I am applying.",
        status: false,
      },
    },

    years: [],
    options: {
      penColor: "black",
    },
    renderkey: 0,
    isCanvasEmpty: true,
    signatureType: "",
    signatureFamily: "dancing",
    imageUrl: null,
  };
},
mounted() {
  this.imageLoad();
  this.addToggleBtnEventListeners();
  this.increaseHeaderInputSize();
  this.calculateDivHeight();
  if (!sessionStorage.getItem("pageLoaded")) {
    this.deleteField();
    this.toggleFieldBtn();
    this.scaleFunction();
    sessionStorage.setItem("pageLoaded", "true");
  }
},
created() {
  this.updateFormById();
  this.generateYears();
  this.debouncedUpdateResume = debounce(this.updateResume, 800);
},
computed: {
  fullNameWordLength() {
    const fullName = (this.resume.name + this.resume.familyName).trim();
    return fullName.length;
  },
  FontSize() {
    if (this.fullNameWordLength >= 70) {
      return ".875rem";
    } else if (this.fullNameWordLength >= 60) {
      return "1.063rem";
    } else if (this.fullNameWordLength >= 50) {
      return "1.25rem";
    } else if (this.fullNameWordLength >= 40) {
      return "1.375rem";
    } else {
      return "1.5rem";
    }
  },
},
 updated() {
    this.calculateDivHeight();
  },
methods: {
  calculateDivHeight() {
    var sectionResumes = document.querySelectorAll('.section_resume');
  var hrElements = document.querySelectorAll('hr.my-2');

  var sectionHeightTotal = 0;
  var hrHeightTotal = 0;
    sectionResumes.forEach(function(element) {
    var sectionMarginTop = parseFloat(window.getComputedStyle(element).marginTop);
    var sectionMarginBottom = parseFloat(window.getComputedStyle(element).marginBottom);
    var sectionHeight = element.getBoundingClientRect().height;
    var childElements = element.querySelectorAll('.education_div');

    childElements.forEach(function(childElement) {
      var childMarginTop = parseFloat(window.getComputedStyle(childElement).marginTop);
      var childMarginBottom = parseFloat(window.getComputedStyle(childElement).marginBottom);
      sectionHeight += childElement.getBoundingClientRect().height + childMarginTop + childMarginBottom + 40;
    });

    sectionHeightTotal += sectionHeight + sectionMarginTop + sectionMarginBottom;
  });

  hrElements.forEach(function(element) {
    var hrMargin = parseFloat(window.getComputedStyle(element).marginTop) + parseFloat(window.getComputedStyle(element).marginBottom);
    hrHeightTotal += element.getBoundingClientRect().height + hrMargin;
  });
    const container = this.$refs.resumePageHeight;
  //  const parent = this.$refs.resumePageHeight;
        const scale = this.getScaleFactor(container);
        const containerHeight = container.offsetHeight / scale;
  // console.log('Total height of section_resume:', sectionHeightTotal, 'px');
  // console.log('Total height of hr.my-2 including margin:', hrHeightTotal, 'px');
  console.log("total",sectionHeightTotal + hrHeightTotal)
  console.log('Container height:', containerHeight, 'px');

  if (containerHeight < sectionHeightTotal + hrHeightTotal) {
    console.log('Condition is true. Adding height-small class.');
      const personalFormDiv = document.createElement('div');
    personalFormDiv.classList.add('personalFormEmpty');
    personalFormDiv.classList.add('personalFormNew');
      const personalFormDivBody = document.createElement('div');
    personalFormDivBody.classList.add('personalForm_body');
    personalFormDiv.appendChild(personalFormDivBody);
    const personalFormDivMain = document.createElement('div');
    personalFormDivMain.classList.add('personalForm_main');
    personalFormDivBody.appendChild(personalFormDivMain);
    const personalFormDivAside = document.createElement('div');
    personalFormDivAside.classList.add('personalForm_aside');
    personalFormDivBody.appendChild(personalFormDivAside);
    const resumePagesDiv = this.$refs.resumePage;
    resumePagesDiv.appendChild(personalFormDiv); 
    const firstDiv =  resumePagesDiv.querySelector('.personalFormEmpty:first-child');
      if (firstDiv) {
        const firstDivRect = firstDiv.getBoundingClientRect();
        const newDivTop = firstDivRect.bottom  - 40;
        // console.log(newDivTop);   
       personalFormDiv.style.top = `${newDivTop}px`;
      }

  }else{
      const resumePagesDiv = this.$refs.resumePage;
      const personalFormDiv = resumePagesDiv.querySelector('.personalFormNew');
      if (personalFormDiv) {
          resumePagesDiv.removeChild(personalFormDiv);
      }
     }
  
  },
  getScaleFactor(element) {
    const transform = window.getComputedStyle(element).transform;
    if (transform && transform !== 'none') {
      const matrix = transform.split('(')[1].split(')')[0].split(',');
      const scaleX = parseFloat(matrix[0]);
      const scaleY = parseFloat(matrix[3]);
      return scaleX === 0 ? scaleY : scaleX;
    }
    return 1;
  },
  renderFunction() {
    setTimeout(() => {
      this.renderkey = this.renderkey + 1;
    }, 600);
    setTimeout(() => {
      document.querySelector("#done").classList.add("empty-canvas");
    }, 650);
  },
  delete_Image() {
    this.resume.profileImage = null;
    this.updateResume();
  },
  delete_signature() {
    this.resume.signatures.signature = null;
    this.updateResume();
  },
  save() {
    const { data } = this.$refs.signaturePad.saveSignature();
    if (data !== undefined) {
      this.resume.signatures.signature = data;
      this.updateResume();
    } else if (this.hasInputValue()) {
      const inputElement = document.getElementById("signatureInput");
      const inputValue = inputElement.value.trim();
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = inputElement.offsetWidth;
      canvas.height = 200;
      console.log(canvas.width);
      const fontFamily = window.getComputedStyle(inputElement).fontFamily;
      const fontSize = window.getComputedStyle(inputElement).fontSize;
      const textColor = window.getComputedStyle(inputElement).color;
      ctx.font = `${fontSize} ${fontFamily}`;
      const textWidth = ctx.measureText(inputValue).width;
      const x = (canvas.width - textWidth) / 2;
      const y = canvas.height / 1.99;
      ctx.fillStyle = textColor;
      ctx.fillText(inputValue, x, y);
      const inputValueAsImage = canvas.toDataURL();
      this.resume.signatures.signature = inputValueAsImage;
      this.updateResume();
    } else if (this.imageUrl !== null) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = this.$refs.uploadedImage;
      const imagefilter = this.$refs.imagefilter;
      canvas.width = image.offsetWidth;
      canvas.height = 200;
      const textFilter = window.getComputedStyle(imagefilter).filter;
      const images = new Image();
      images.src = this.imageUrl;
      images.onload = () => {
        ctx.filter = textFilter;
        ctx.drawImage(images, 0, 0);
        const dataURL = canvas.toDataURL();
        this.resume.signatures.signature = dataURL;
        this.updateResume();
      };
    } else {
      console.log("No signature or input value or Image to save.");
    }
    this.signatureType = "";
    this.imageUrl = null;
    setTimeout(() => {
      // window.location.reload();
    }, 1000);
  },
  hasInputValue() {
    const inputElement = document.getElementById("signatureInput");
    return inputElement && inputElement.value.trim() !== "";
  },
  closeModel() {
    this.signatureType = "";
    this.imageUrl = null;
  },
  handleSignatureChange(data) {
    this.isCanvasEmpty = data.isEmpty;
    document.querySelector("#done").classList.remove("empty-canvas");
    document.querySelector("#clear").classList.remove("d-none");
  },
  changeColor(color) {
    this.options = {
      penColor: color,
    };
  },
  handleClearSignautre() {
    this.$refs.signaturePad.clearSignature();
    document.querySelector("#clear").classList.add("d-none");
    document.querySelector("#done").classList.add("empty-canvas");
  },
  updateSignatureValue(event) {
    this.signatureType = event.target.value;
    const length = this.signatureType.length;
    const inputElement = document.getElementById("signatureInput");
    console.log(length);

    let fontSize = "55px";
    if (length == 30) {
      fontSize = "36px";
      inputElement.maxLength = 30;
    } else if (length >= 25) {
      fontSize = "40px";
    } else if (length >= 20) {
      fontSize = "48px";
    } else {
      fontSize = "55px";
    }
    inputElement.style.fontSize = fontSize;
  },
  changeFamily(font) {
    this.signatureFamily = font;
  },
  openFileInput() {
    this.$refs.fileInput.click();
  },
  handleFileInput(event) {
    const files = event.target.files;
    this.handleFiles(files);
  },
  handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    this.handleFiles(files);
  },
  handleFiles(files) {
    if (files.length > 0) {
      const file = files[0];
      this.displayImage(file);
    }
  },
  displayImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.imageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  },
  deleteImage() {
    this.imageUrl = null;
  },
  scrollToTop(refName) {
    const refElement = this.$refs[refName];
    const formWrapper = this.$el.querySelector(".form_resume_wrapper");
    const refPosition = refElement.offsetTop - formWrapper.offsetTop - 20;
    formWrapper.scrollTo({
      top: refPosition,
      behavior: "smooth",
    });
  },
  getSkillLevelPercentage(skillLevel) {
    return (skillLevel / 5) * 100 + "%";
  },
  getSkillLevelText(skillLevel) {
    if (skillLevel >= 5) {
      return "Excellent";
    } else if (skillLevel >= 4) {
      return "Very good";
    } else if (skillLevel >= 3) {
      return "Good";
    } else if (skillLevel >= 2) {
      return "Moderate";
    } else if (skillLevel >= 1) {
      return "Beginner";
    } else {
      return "Make a choice";
    }
  },
  getLanguageLevelPercentage(languageLevel) {
    return (languageLevel / 5) * 100 + "%";
  },
  getLanguageLevelText(languageLevel) {
    if (languageLevel >= 5) {
      return "Fluent";
    } else if (languageLevel >= 4) {
      return "Very good";
    } else if (languageLevel >= 3) {
      return "Good";
    } else if (languageLevel >= 2) {
      return "Moderate";
    } else if (languageLevel >= 1) {
      return "Beginner";
    } else {
      return "Make a choice";
    }
  },
  generateYears() {
    const startYear = 1920;
    const endYear = 2026;
    for (let year = endYear; year >= startYear; year--) {
      this.years.push(year);
    }
  },
  addEducation() {
    this.resume.educations.push({
      education: "",
      school: "",
      city: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.educations.length - 1,
      });
    }, 900);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperEdu");
    });
    this.updateResume();
    this.updateFormById();
  },
  addEmployer() {
    this.resume.employments.push({
      position: "",
      employer: "",
      city: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.employments.length - 1,
      });
    }, 800);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperEmp");
    });
    this.updateResume();
    this.updateFormById();
  },
  addSkill() {
    this.resume.skills.push({
      skill: "",
      skillLevel: 0,
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.skills.length - 1,
      });
    }, 680);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperSkl");
    });

    this.updateResume();
    this.updateFormById();
  },
  addLanguage() {
    this.resume.languages.push({
      language: "",
      languageLevel: 0,
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.languages.length - 1,
      });
    }, 650);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperLang");
    });

    this.updateResume();
    this.updateFormById();
  },
  addHobby() {
    this.resume.hobbies.push({
      hobby: "",
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.hobbies.length - 1,
      });
    }, 650);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperHy");
    });
    this.updateResume();
    this.updateFormById();
  },
  addCourse() {
    this.resume.courses.push({
      course: "",
      period_month: "",
      period_year: "",
      description: "",
      person_id: "",
      active: true,
    });

    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.courses.length - 1,
      });
    }, 650);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperCor");
    });
    this.updateResume();
    this.updateFormById();
  },
  addProfile() {
    this.resume.profiles.push({
      description: "",
      person_id: "",
      active: true,
    });
    this.$nextTick(() => {
      this.scrollToTop("formWrapper");
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.profiles.length - 1,
      });
    }, 680);
    this.updateResume();
    this.updateFormById();
  },
  addInternship() {
    this.resume.internships.push({
      position: "",
      employer: "",
      city: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.internships.length - 1,
      });
    }, 820);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperInt");
    });
    this.updateResume();
    this.updateFormById();
  },
  addActivity() {
    this.resume.activities.push({
      position: "",
      employer: "",
      city: "",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      description: "",
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.activities.length - 1,
      });
    }, 820);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperAct");
    });
    this.updateResume();
    this.updateFormById();
  },
  addRefecence() {
    this.resume.references.push({
      name: "",
      organization: "",
      city: "",
      phone: "",
      email: "",
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.references.length - 1,
      });
    }, 820);
    this.$nextTick(() => {
      this.scrollToTop("formWrapperRef");
    });
    this.updateResume();
    this.updateFormById();
  },
  addQuality() {
    this.resume.qualities.push({
      quality: "",
      person_id: "",
      active: true,
    });
    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.qualities.length - 1,
      });
    }, 650);
    this.updateResume();
    this.updateFormById();
  },
  addCertificate() {
    this.resume.certificates.push({
      certificate: "",
      monthPeriod: "",
      yearPeriod: "",
      description: "",
      person_id: "",
      active: true,
    });

    setTimeout(() => {
      this.handleBackendResponse({
        addedIndex: this.resume.certificates.length - 1,
      });
    }, 650);
    this.updateResume();
    this.updateFormById();
  },
  deleteEducation(index) {
    const educationId = this.resume.educations[index].id;
    if (!educationId) {
      alert("Education entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${educationId}/education`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.educations.splice(index, 1);
        this.updateResume();
        this.resume.educations[newIndex].active = true;
        this.$nextTick(() => {
          this.scrollToTop("formWrapperEdu");
        });
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  deleteEmploymnet(index) {
    const employmentId = this.resume.employments[index].id;
    if (!employmentId) {
      alert("Employment entry does not have an ID.");
      return;
    }

    axios
      .get(
        `${process.env.VUE_APP_API_URL}/delete_detail/${employmentId}/employment`
      )
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.employments.splice(index, 1);
        this.updateResume();
        this.resume.employments[newIndex].active = true;
        this.$nextTick(() => {
          this.scrollToTop("formWrapperEmp");
        });
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  deleteSkill(index) {
    const skillId = this.resume.skills[index].id;
    if (!skillId) {
      alert("Skill entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${skillId}/skill`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.skills.splice(index, 1);
        this.resume.skills[newIndex].active = true;
        this.updateResume();
        this.$nextTick(() => {
          this.scrollToTop("formWrapperSkl");
        });
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  deleteLanguage(index) {
    const languageId = this.resume.languages[index].id;
    if (!languageId) {
      alert("Language entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${languageId}/language`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.languages.splice(index, 1);
        this.resume.languages[newIndex].active = true;
        this.updateResume();
        this.$nextTick(() => {
          this.scrollToTop("formWrapperLang");
        });
      })
      .catch((error) => {
        console.error("Error deleting:", error);
      });
  },
  deleteHobby(index) {
    const hobbyId = this.resume.hobbies[index].id;
    if (!hobbyId) {
      alert("Hobby entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${hobbyId}/hobby`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.hobbies.splice(index, 1);
        this.resume.hobbies[newIndex].active = true;
        this.updateResume();
        this.$nextTick(() => {
          this.scrollToTop("formWrapperHy");
        });
      })
      .catch((error) => {
        console.error("Error deleting:", error);
      });
  },
  deleteCourse(index) {
    const courseId = this.resume.courses[index].id;
    if (!courseId) {
      alert("Course entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${courseId}/course`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.courses.splice(index, 1);
        this.resume.courses[newIndex].active = true;
        this.updateResume();
        this.$nextTick(() => {
          this.scrollToTop("formWrapperCor");
        });
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  deleteProfile(index) {
    const profileId = this.resume.profiles[index].id;
    if (!profileId) {
      alert("Profile entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${profileId}/profile`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.profiles.splice(index, 1);
        this.resume.profiles[newIndex].active = true;
        this.$nextTick(() => {
          this.scrollToTop("formWrapper");
        });

        this.updateResume();
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  deleteInternship(index) {
    const internshipId = this.resume.internships[index].id;
    if (!internshipId) {
      alert("Internships entry does not have an ID.");
      return;
    }

    axios
      .get(
        `${process.env.VUE_APP_API_URL}/delete_detail/${internshipId}/internship`
      )
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.internships.splice(index, 1);
        this.updateResume();
        this.$nextTick(() => {
          this.scrollToTop("formWrapperInt");
        });
        this.resume.internships[newIndex].active = true;
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  deleteActivity(index) {
    const activityId = this.resume.activities[index].id;
    if (!activityId) {
      alert("Activity entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${activityId}/activity`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.activities.splice(index, 1);
        this.updateResume();
        this.resume.activities[newIndex].active = true;
        this.$nextTick(() => {
          this.scrollToTop("formWrapperAct");
        });
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  deleteReference(index) {
    const referenceId = this.resume.references[index].id;
    if (!referenceId) {
      alert("Reference entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${referenceId}/reference`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.references.splice(index, 1);
        this.updateResume();
        this.resume.references[newIndex].active = true;
        this.$nextTick(() => {
          this.scrollToTop("formWrapperRef");
        });
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  deleteQuality(index) {
    const qualityId = this.resume.qualities[index].id;
    if (!qualityId) {
      alert("Quality entry does not have an ID.");
      return;
    }

    axios
      .get(`${process.env.VUE_APP_API_URL}/delete_detail/${qualityId}/quality`)
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.qualities.splice(index, 1);
        this.resume.qualities[newIndex].active = true;
        this.updateResume();
      })
      .catch((error) => {
        console.error("Error deleting:", error);
      });
  },
  deleteCertificate(index) {
    const ertificateId = this.resume.certificates[index].id;
    if (!ertificateId) {
      alert("Certificate entry does not have an ID.");
      return;
    }
    axios
      .get(
        `${process.env.VUE_APP_API_URL}/delete_detail/${ertificateId}/certificate`
      )
      .then(() => {
        const newIndex = index > 0 ? index - 1 : 0;
        this.resume.certificates.splice(index, 1);
        this.resume.certificates[newIndex].active = true;
        this.updateResume();
      })
      .catch((error) => {
        console.error("Error deleting :", error);
      });
  },
  async updateFormById() {
    let url = `${process.env.VUE_APP_API_URL}/person_detail/${this.$route.params.id}`;
    try {
      const response = await axios.get(url);
      const {
        resume,
        educations,
        employments,
        skills,
        languages,
        hobbies,
        courses,
        profiles,
        internships,
        activities,
        references,
        qualities,
        certificates,
        achievement,
        signatures,
        footer,
      } = response.data;
      this.resume = resume;
      this.resume.educations = educations;
      this.resume.employments = employments;
      this.resume.skills = skills;
      this.resume.languages = languages;
      this.resume.hobbies = hobbies;
      this.resume.courses = courses;
      this.resume.profiles = profiles;
      this.resume.internships = internships;
      this.resume.activities = activities;
      this.resume.references = references;
      this.resume.qualities = qualities;
      this.resume.certificates = certificates;
      this.resume.achievement = achievement;
      this.resume.signatures = signatures;
      this.resume.footer = footer;
    } catch (error) {
      console.error("Error fetching resume:", error);
    }
  },
  scaleFunction() {
    const personalForms = document.getElementById("personalForm");
    personalForms.classList.toggle("zoom");
  },
  addToggleBtnEventListeners() {
    const toggleBtns = document.querySelectorAll(".toggle_btn.collapsed");
    toggleBtns.forEach((button) => {
      button.addEventListener("click", function () {
        const accordionHeader = this.closest(".accrodion_header");
        accordionHeader.classList.toggle("active");
      });
    });

    const toggleBtnsCollapse = document.querySelectorAll(
      ".toggle_btn_collapse"
    );
    toggleBtnsCollapse.forEach((button) => {
      button.addEventListener("click", function () {
        const accordionHeader = this.closest(".accrodion_header");
        accordionHeader.classList.toggle("active");
      });
    });
  },
  toggleForm(index) {
    this.resume.skills.forEach((skill, i) => {
      skill.active = i === index;

      if (i !== index) {
        skill.active = false;
      }
    });
    this.resume.educations.forEach((education, i) => {
      education.active = i === index;

      if (i !== index) {
        education.active = false;
      }
    });
    this.resume.employments.forEach((employment, i) => {
      employment.active = i === index;

      if (i !== index) {
        employment.active = false;
      }
    });
    this.resume.languages.forEach((language, i) => {
      language.active = i === index;

      if (i !== index) {
        language.active = false;
      }
    });
    this.resume.hobbies.forEach((hobby, i) => {
      hobby.active = i === index;

      if (i !== index) {
        hobby.active = false;
      }
    });
    this.resume.courses.forEach((course, i) => {
      course.active = i === index;

      if (i !== index) {
        course.active = false;
      }
    });
    this.resume.profiles.forEach((profile, i) => {
      profile.active = i === index;

      if (i !== index) {
        profile.active = false;
      }
    });
    this.resume.internships.forEach((internship, i) => {
      internship.active = i === index;

      if (i !== index) {
        internship.active = false;
      }
    });
    this.resume.activities.forEach((activity, i) => {
      activity.active = i === index;

      if (i !== index) {
        activity.active = false;
      }
    });
    this.resume.references.forEach((reference, i) => {
      reference.active = i === index;

      if (i !== index) {
        reference.active = false;
      }
    });
    this.resume.qualities.forEach((quality, i) => {
      quality.active = i === index;

      if (i !== index) {
        quality.active = false;
      }
    });
    this.resume.certificates.forEach((certificate, i) => {
      certificate.active = i === index;

      if (i !== index) {
        certificate.active = false;
      }
    });
  },
  handleBackendResponse(response) {
    const addedIndex = response.addedIndex;
    this.resume.skills.forEach((skill, index) => {
      skill.active = index === addedIndex;
    });
    this.resume.educations.forEach((education, index) => {
      education.active = index === addedIndex;
    });
    this.resume.employments.forEach((employment, index) => {
      employment.active = index === addedIndex;
    });
    this.resume.languages.forEach((language, index) => {
      language.active = index === addedIndex;
    });
    this.resume.hobbies.forEach((hobby, index) => {
      hobby.active = index === addedIndex;
    });
    this.resume.courses.forEach((course, index) => {
      course.active = index === addedIndex;
    });
    this.resume.profiles.forEach((profile, index) => {
      profile.active = index === addedIndex;
    });
    this.resume.internships.forEach((internship, index) => {
      internship.active = index === addedIndex;
    });
    this.resume.activities.forEach((activity, index) => {
      activity.active = index === addedIndex;
    });
    this.resume.references.forEach((reference, index) => {
      reference.active = index === addedIndex;
    });
    this.resume.qualities.forEach((quality, index) => {
      quality.active = index === addedIndex;
    });
    this.resume.certificates.forEach((certificate, index) => {
      certificate.active = index === addedIndex;
    });
  },
  increaseHeaderInputSize() {
    const inputs = document.querySelectorAll(".header_input");
    inputs.forEach((input) => {
      input.addEventListener("input", () => {
        const maxWidthChars = 60;
        const minWidth = 150;
        const charWidth = 8;

        const inputValueLength = input.value.length;
        let width = inputValueLength * charWidth;

        if (width < minWidth) {
          width = minWidth;
        } else if (inputValueLength > maxWidthChars) {
          width = maxWidthChars * charWidth;
        }

        input.style.width = width + "px";
      });
    });
  },
  imageLoad() {
    let profilepic = document.getElementById("image");
    let inputfile = document.getElementById("choose");
    inputfile.onchange = function () {
      profilepic.src = URL.createObjectURL(inputfile.files[0]);
    };
  },
  toggleFieldBtn(className) {
    const correspondingButton = document.querySelector(
      `.resume_form .hidden_input.${className} `
    );
    const clickButton = document.querySelector(
      `.form_widget  button.${className}`
    );

    if (correspondingButton) {
      correspondingButton.classList.add("d-block");
      correspondingButton.classList.remove("d-none");
    }
    if (clickButton) {
      clickButton.classList.add("d-none");
    } else {
      ("error");
    }
  },
  deleteField(className) {
    if (className == "date_birthField") {
      this.resume.dateOfBirth = "";
    }
    if (className == "place_birthField") {
      this.resume.placeOfBirth = "";
    }
    if (className == "driver_licenseField") {
      this.resume.drivingLicense = "";
    }
    if (className == "gender_btnField") {
      this.resume.gender = "";
    }
    if (className == "nationality_btnField") {
      this.resume.nationality = "";
    }
    if (className == "civil_btnField") {
      this.resume.civilState = "";
    }
    if (className == "website_btnField") {
      this.resume.website = "";
    }
    if (className == "linkedin_btnField") {
      this.resume.linkedin = "";
    }

    const correspondingButton = document.querySelector(
      `.resume_form .hidden_input.${className}`
    );
    const correspondingButtonParent = document.querySelector(
      `.form_widget button.${className}`
    );

    if (correspondingButton) {
      correspondingButton.classList.add("d-none");
      correspondingButton.classList.remove("d-block");
    }
    if (correspondingButtonParent) {
      correspondingButtonParent.classList.remove("d-none");
    }
    this.updateResume();
  },
  mainFieldBtn(className) {
    const correspondingButton = document.querySelector(
      `.form_resume_wrapper .hidden_fields.${className} `
    );
    const clickButton = document.querySelector(
      `.field_widgets  button.${className}`
    );

    if (correspondingButton) {
      correspondingButton.classList.add("d-block");
      correspondingButton.classList.remove("d-none");
      const parentSection = correspondingButton.closest(".accordion_wrapper");
      const parentSectionHeader =
        parentSection.querySelector(".accrodion_header ");
      const collapseButton = parentSection.querySelector(
        ".toggle_btn_collapse"
      );
      const collapse = parentSection.querySelector(".collapse ");
      parentSectionHeader.classList.add("active");
      collapseButton.classList.remove("collapsed");
      collapse.classList.add("show");
    }
    if (clickButton) {
      clickButton.classList.add("d-none");
    } else {
      ("error");
    }
  },
  handelShowFooter() {
    this.resume.footer.status = true;
      this.updateResume();
    // if (this.resume.footer.status !== true) {
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 400);
    // }
  },
  async updateResume() {
    try {
      const formData = new FormData();
      formData.append("resume", JSON.stringify(this.resume));

      const personalResponse = await axios.post(
        `${process.env.VUE_APP_API_URL}/update_detail/${this.$route.params.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (personalResponse.status === 200) {
        this.resume.id = personalResponse.data.id;
      }
    } catch (error) {
      console.error("Error submitting resume:", error);
    }
  },
  handleFileChange(event) {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = async () => {
      const profileImageBase64 = reader.result;
      this.resume.profileImage = profileImageBase64;
      this.updateResume();
    };
  },

  downloadResume() {
    const resumePage = this.$refs.resumePage;
    //  resumePage.style.position = 'relative';
    // resumePage.style.transform = 'scale(1)';
    // resumePage.style.top = '0px';
    // resumePage.style.left = '0px';

      const personalFormEmptyElements = resumePage.querySelectorAll('.personalFormEmpty');

      personalFormEmptyElements.forEach(element => {
          element.style.position = 'relative';
          element.style.transform = 'scale(1)';
          element.style.top = '0px';
          element.style.left = '0px';
      });

    html2pdf()
      .from(resumePage)
      .save(this.resume.name);
      setTimeout(() => {
        // resumePage.style.transform = 'scale(.4) translateX(-50%)';
        // resumePage.style.position = 'absolute';
        // resumePage.style.top = '35px';
        //  resumePage.style.left = '50%';

          personalFormEmptyElements.forEach(element => {
            element.style.transform = 'scale(.4) translateX(-50%)';
          element.style.position = 'absolute';
          element.style.top = '35px';
          element.style.left = '50%';
      });

      }, 1);
    },

},
};
</script>

<style lang="scss">
@import '../styles/download.scss';
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Parisienne&family=Sacramento&display=swap');
$border-color: #949494;
$input-color: #f8f9fa;
$input-font: 0.875rem;
$font-dancing:"Dancing Script", cursive;
$font-parisienne:"Parisienne", cursive;
$font-sacramento:"Sacramento", cursive;
body {
  font-family: "Poppins", sans-serif;
 

  .create_resume {
    .resume_header {
      width: 100%;
      height: 70px;
      background-color: #1d1d20;
      display: flex;
      align-items: center;
      position: fixed;
      z-index: 111;
      a {
        text-decoration: none;
        border: 1px solid #595959;
        padding: 0.6rem 1rem;
        border-radius: 4px;
        background-color: #232324;
        color: #fff;
      }
    }
    .create_resume_wrapper {
      display: flex;
      padding-top: 70px;
      .form_resume_wrapper {
        width: 50%;
        height: calc(100vh - 70px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px 30px 20px 30px;

        .field_widgets button.hidden {
          display: none;
        }
        .field_widgets button.show {
          display: block;
        }

        .accordion_wrapper.hidden {
          display: none;
        }
        .accordion_wrapper.show {
          display: block;
        }
        .accordion_wrapper {
          input.header_input {
            width: 150px;
            border: 0;
            font-size: 0.93rem;
            padding: 0;
            border-radius: 0;
            font-weight: 600;
              border-bottom: 1px solid transparent;

            &:hover {
              border-bottom: 1px solid $border-color;
            }
            &:focus {
              box-shadow: none;
              border-bottom: 1px solid $border-color;
            }
            ::placeholder {
              color: #595959;
            }
          }
          .accrodion_header.active {
            border: 0;
            .form_header {
              input {
                pointer-events: all;
                color: #000000;

                &:focus {
                  box-shadow: none;
                  border-bottom: 1px solid $border-color;
                }
                ::placeholder {
                  color: #595959;
                }
              }
            }
          }
          .accrodion_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            border-bottom: 1px solid $border-color;
            margin: 15px 0;

            .form_header {
              display: flex;
              width: calc(100% - 37px);
              align-items: center;

              input {
                pointer-events: none;
                color: #838383;
                width: 200px;
                border: 0;
                font-size: 0.93rem;
                padding: 0;
                border-radius: 0;
                font-weight: 600;
                background-color: transparent;
                border-bottom: 1px solid transparent;

                &:hover {
                  border-bottom: 1px solid $border-color;
                }
                &:focus {
                  box-shadow: none;
                  border-bottom: 1px solid $border-color;
                }
                ::placeholder {
                  color: #595959;
                }
              }

              button {
                width: 30px;
                height: 30px;
                border-radius: 5px;
                background: transparent;
                border: 1px solid $border-color;

                i {
                  font-size: 13px;
                  color: #1d1d20;
                }
              }
            }
            .toggle_btn {
              width: 30px;
              height: 30px;
              border-radius: 5px;
              background: transparent;
              border: 1px solid $border-color;
              padding: 0;
              i {
                transition: all 0.4s ease;
              }
            }
            .toggle_btn.collapsed {
              i {
                transform: rotate(180deg);
              }
            }
          }
          .resume_form {
            .signature_Wrapper {
              width: 230px;
              div {
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              .signture_btn {
                width: 100%;
                height: 140px;
                background-color: #f8f9fa;
                border-radius: 5px;
                cursor: pointer;

                img{
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  object-position: center;
                }
              }
            }
            .closeForms {
              .form_openFiedls {
                display: block !important;
              }
              .form_fields {
                display: none;
              }
              .form_btn {
                display: none !important;
              }
            }
            .closeForms.openForm {
              .form_openFiedls {
                display: none !important;
              }
              .form_fields {
                display: block;
              }
              .form_btn {
                display: block !important;
              }
            }
            .choose-img {
              width: 100%;
              margin-bottom: 10px;
              height: 8.4rem;
              p {
                margin: 4px;
              }
              .deleteDropdown {
                  button{
                    &:focus{
                    outline: 0;
                    }
                    .dropdown-menu{
                      border: 0;
                      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

                      a{
                        &:hover{
                          background-color: blue;
                          color:#fff;
                        }
                      }
                    }
                  }
              }
              #select_img {
                height: calc(100% - 30px);
                width: 110px;

                img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  border-radius: 4px;
                  border: 1px solid #f7f7f7;
                  cursor: pointer;
                }
              }
              #choose {
                display: none;
              }
            }
            .resume_input {
              background-color: $input-color;
              width: 100%;
              border-color: transparent;
              padding: 4px 10px 5px 10px;
              font-size: $input-font;

              &:hover,
              &:focus {
                border-color: rgb(93, 37, 231);
                box-shadow: none;
              }
            }
            .resume_label {
              font-size: 0.93rem;
            }
            .hidden_input.hidden {
              display: none;
            }
            .hidden_input.show {
              display: block;
            }
            .hidden_input_wrappr {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
              .dropdown {
                .removeField_Btn {
                  border: 0;
                  border-radius: 5px;
                  background-color: transparent;
                }
                .remove_field {
                  width: 170px;
                  border: 0;
                  background-color: #fff;
                  padding: 10px 0;
                  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);

                  li {
                    font-size: 0.93rem;
                    margin: 0;
                    padding: 5px 0;
                    padding-left: 20px;
                    cursor: pointer;

                    &:hover {
                      background-color: rgb(93, 37, 231);
                      color: #fff;
                    }
                  }
                }
              }
            }
            .form_fields {
              .range_div {
                display: flex;
                align-items: center;
                gap: 20px;
                input {
                  width: 250px;
                }
                input::-webkit-slider-thumb {
                  -webkit-appearance: none;
                  appearance: none;
                  background: rgb(93, 37, 231);
                }
                p {
                  margin: 0;
                  font-size: $input-font;
                }
              }
            }
            .form_openFiedls {
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;

              button {
                background-color: transparent;
                border: 1px solid $border-color;
                border-radius: 5px;
                padding: 5px 10px;
                font-size: 10px;
              }
            }
          }

          .resume_formBorder {
            border: 1px solid $border-color;
            border-radius: 5px;
            padding: 10px;
            .form_openFiedls {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
          .form_widget {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            button {
              border: 1px solid $border-color;
              border-radius: 5px;
              padding: 8px 10px;
              background-color: transparent;
              max-width: 165px;
              min-width: 120px;

              &:hover {
                border-color: rgb(93, 37, 231);
              }
            }
          }

          .add_btn {
            border: 1px solid $border-color;
            border-radius: 5px;
            background-color: transparent;
            padding: 5px 10px;
            margin-top: 15px;
          }
        }

        .field_widgets {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 50px;
          button {
            border: 1px solid $border-color;
            border-radius: 5px;
            padding: 8px 10px;
            background-color: transparent;
            max-width: 265px;
            min-width: 100px;
            font-weight: 500;

            &:hover {
              border-color: rgb(93, 37, 231);
            }
          }
        }
        .download{
          display: flex;
          justify-content: end;
          margin-top: 20px;
          button{
            background-color: rgb(93, 37, 231); 
            padding: 10px 20px;
            border: 0;
            border-radius: 5px;
            color: #fff;
            font-weight: 500;

            &:hover{
              background-color: rgb(87, 24, 245); 

            }
         }
        }
        .signatureModal {
          .modal-dialog {
            position: relative;
            height: 100vh;
            margin: 0 auto;
            .modal-content {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              .modal-header {
                border: 0;
                user-select: none;
              }

              .modal-body {
                .tabsSignature {
                  .nav-pills {
                    width: 100%;
                    display: flex;
                    border: 1px solid $border-color;
                    border-radius: 5px;
                    .nav-item {
                      flex: 1;
                      button {
                        width: 100%;
                        border-radius: 0px;
                        color: #000000;
                        font-weight: 500;
                        user-select: none;
                      }
                      button.active {
                        background-color: rgb(93, 37, 231);
                        color: #fff;
                      }
                    }
                  }
                  .signature_fields {
                    width: 100%;
                    height: auto;
                    border-radius: 5px;
                    .upload_image{
                      width: 100%;
                      height: 200px;
                      border: 1px dashed $border-color;
                      border-radius: 5px;
                      text-align: center;

                      div{
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        display: flex;justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        p{
                          font-size: 13px;
                          max-width: 200px;
                          text-align: center;
                          margin: 0;
                          line-height: 1.1;
                        }
                        button{
                          background-color: rgb(93, 37, 231);
                          border: 0;
                          color: #fff;
                          border-radius: 5px;
                          padding: 5px 15px;
                          margin-top: 10px;
                          font-size: 14px;
                          &:hover{
                            background-color: rgba(93, 37, 231,.9);
                          }
                        }
                      }

                      img{
                        height: 100%;
                        width: auto;
                      }
                      img.black{
                        filter: invert(0%) sepia(2%) saturate(0%) hue-rotate(253deg) brightness(103%) contrast(100%);
                      }
                      img.red{
                        filter: invert(50%) sepia(76%) saturate(7055%) hue-rotate(7deg) brightness(95%) contrast(128%);
                      }
                      img.blue{
                        filter: invert(50%) sepia(96%) saturate(7490%) hue-rotate(248deg) brightness(94%) contrast(144%);
                      }
                    }
                    input {
                      width: 100%;
                      height: 200px;
                      text-align: center;
                      background-color: #e4e4e7;
                      border: 0;
                      border-radius: 5px;
                      font-size: 55px;
                      font-family: $font-dancing;
                      &:focus {
                        outline: 0;
                      }
                    }
                    input.red {
                      color: red;
                    }
                    input.blue {
                      color: blue;
                    }

                     .dancing{
                        font-family: $font-dancing;
                      }
                      .parisienne{
                        font-family: $font-parisienne;
                      }
                      .sacramento{
                        font-family: $font-sacramento;
                      }



                    #signature {
                      border-radius: 5px;
                      background-color: #e4e4e7;
                      height: 200px;
                    }
                    .button_wrapper {
                      display: flex;
                      justify-content: space-between;
                      margin-top: 8px;
                      align-items: center;
                      .btns {
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        button {
                          width: 25px;
                          height: 25px;
                          border-radius: 50%;
                          border: 0;
                          display: flex;
                          justify-content: center;
                          align-items: center;

                          i {
                            color: #fff;
                            font-size: 14px;
                          }
                        }
                        .fontDiv {
                          border-radius: 5px;
                          width: 100px;
                          margin-left: 10px;
                         border:2px solid transparent;

                          &:hover {
                            background-color: #ebebeb;
                            border: 2px solid rgb(93, 37, 231);
                          }
                          button {
                            width: 100%;
                          }
                          .dropdown-menu {
                            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                            border: 0;

                            a{
                              display: flex;
                              align-items: center;
                              font-size: 20px;
                              &:hover {
                                background-color: blue;
                                color: #fff;
                              }
                              p{
                                width: 30px;
                                margin: 0;
                                i{
                                  font-size: 14px;
                                }
                                }
                            }
                            a.active{
                              background-color: blue;
                              color: #fff; 
                            }
                            .font-1{
                              font-family: $font-dancing;
                            }
                            .font-2{
                              font-family: $font-parisienne;
                            }
                            .font-3{
                              font-family: $font-sacramento;
                            }

                          }
                        }
                        .red {
                          background-color: rgb(255, 0, 0);
                        }
                        .blue {
                          background-color: rgb(0, 0, 255);
                        }
                        .black {
                          background-color: rgb(0, 0, 0);
                        }
                        p {
                          font-size: 14px;
                          margin: 0;
                          opacity: 0.8;
                          cursor: pointer;
                          margin-left: 10px;
                        }
                        .none{
                          display: none;
                        }
                      }
                      .done {
                        background-color: rgb(93, 37, 231);
                        border: 0;
                        color: #fff;
                      }
                      .empty-canvas {
                        pointer-events: none;
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      // resume page

      .resumePages {
        width: 50%;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 35px 30px;
        background-color: #f8f8f8;
        height: calc(100vh - 70px );

          .form-wrapper.zoom {
            cursor: zoom-out;
              transform: scale(1.73) translateX(0%) !important;     

          }

          hr
          {
            margin: 20px 0 !important;
          }

          // .personalForm {
          //   // width: 23vw ;
          //   // width: 794px;
          //   width: 350px;
          //   // height: 55vh;
          //   height: 449.2px;
          //   // height: 1123px;
          //   // transform: scaleY(.4);
          //   margin: auto;
          //   background-color: #fff;
          //   border-radius: 6px;
          //   box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          //   cursor: zoom-in;

          //   .resume_object_heading {
          //     font-size: .575rem;
          //     margin: 0;
          //     font-weight: 500;
          //     margin-bottom: 2px;
          //   }
          //   .personalForm_header {
          //     width: 100%;
          //     height: 60px;
          //     background-color: #4d4d4d;
          //     display: flex;
          //     user-select: none;
          //     align-items: center;
          //     border-top-right-radius: 6px;
          //     border-top-left-radius: 6px;

          //     .person_img {
          //       width: 65px;
          //       height: 100%;
          //       border-top-left-radius: 6px;
          //       background: #fff;
          //       img {
          //         width: 100%;
          //         height: 100%;
          //         border-top-left-radius: 6px;
          //       }
          //     }
          //     .person_content.person_fullContnet {
          //       width: calc(100% - 65px);
          //     }
          //     .person_content {
          //       width: 100%;
          //       padding: 5px 5px 5px 10px;
          //       color: #fff;
          //       .person_fullName {
          //         margin: 0;
          //         overflow: hidden;
          //         span {
          //           margin: 0;
          //           font-weight: 400;
          //           white-space: nowrap;
          //         }
          //       }
          //       .longText {
          //         text-overflow: ellipsis;
          //         overflow: hidden;
          //       }
          //       .person_headline {
          //         p {
          //           margin: 0;
          //           font-size: .5rem ;
          //           opacity: 0.8;
          //           margin-top: -4px;
          //         }
          //       }
          //       .person_address {
          //         display: flex;
          //         align-items: center;
          //         flex-wrap: wrap;
          //         gap: 3px;
          //         p {
          //           font-size: .375rem ;
          //           margin: 0;
          //           display: flex;
          //           gap: 3px;
          //           align-items: center;
          //           opacity: 0.8;

          //           i {
          //           font-size: .3rem; ;
          //             margin-top: 2px;
          //           }
          //         }
          //         span {
          //           font-size: .4rem; ;
          //           margin: 0;
          //         }
          //       }
          //     }
          //   }
          //   .personalForm_body {
          //     padding: 10px 0;
          //     display: flex;
          //     height: calc(100% - 60px);
          //     background-color: #ffffff;
          //     .personalForm_main {
          //       width: 75%;
          //       padding: 0 5px;

          //       .education_div {
          //         margin-bottom: 3px;
          //         h5 {
          //           font-size: .5rem ;
          //           margin: 0;
          //           font-weight: 400;
          //           margin: 2px 0;
          //         }
          //         p {
          //           font-size: .5rem ;
          //           margin: 0;
          //           font-weight: 400;
          //           width: 95%;
          //           word-break: break-all;
          //         }
          //         .education_date {
          //           display: flex;
          //           align-items: flex-start;
          //           justify-content: space-between;
          //           .edu_school_wrapper {
          //             padding-right: 10px;
          //             .edu_school {
          //               display: flex;
          //               align-items: center;
          //               gap: 2px;
          //               width: fit-content;

          //               p {
          //                 font-size: .45rem; ;
          //                 margin: 0;
          //                 opacity: 0.8;
          //                 width: fit-content;
          //                 span {
          //                 font-size: .45rem; ;
          //                 }
          //               }
          //             }
          //           }

          //           .edu_date_time {
          //             display: flex;
          //             align-items: center;
          //             gap: 2px;
          //             float: right;
          //             width: fit-content;
          //             height: 13px;
          //             flex: 0 0 auto;
          //             p {
          //               font-size: .45rem; ;
          //               margin: 0;
          //               opacity: 0.8;
          //               width: fit-content;
          //             }
          //               span {
          //                 font-size: .45rem; ;
          //                 opacity: 0.8;
          //               }
          //           }
          //         }
          //       }
          //     }
          //     .signature_div {
          //       div{
          //       display: flex;align-items: center;
          //       gap: 2px;
          //       p{
          //         margin: 0;
          //         font-size: 6px;
          //       }
          //       }
          //       img{
          //         width: 100px;
          //         height: auto;
                  
          //       }
          //     }

          //     .personalForm_aside {
          //       width: 25%;
          //       height: 100%;
          //       border-left: 1px solid #e0e0e0;
          //       padding: 0 5px;
          //       h6 {
          //     font-size: .575rem;;
          //         margin: 0;
          //         font-weight: 400;
          //       }

          //       .person_extraInfo {
          //         margin-top: -6px;
          //         label {
          //               font-size: .45rem; ;
          //           margin: 0;
          //         }
          //         p {
          //               font-size: .45rem; ;
          //           margin: 0;
          //           margin-top: -3px;
          //           width: 100%;
          //           word-break: break-all;
          //         }
          //       }
          //       .aside_data_wrapper {
          //         margin-bottom: 10px;

          //         h6 {
          //     font-size: .575rem;;
          //           margin: 0;
          //           font-weight: 500;
          //           margin-bottom: 2px;
          //         }
          //         .aside_data {
          //           margin: 5px 0;
          //           h5 {
          //               font-size: .45rem; ;
          //             margin: 0;
          //             font-weight: 400;
          //             margin: 2px 0;
          //             width: 100%;
          //             word-break: break-all;
          //           }
          //           .progress {
          //             height: 4px;
          //             border-radius: 0px;
          //             .progress-bar {
          //               background-color: #1d1d20;
          //             }
          //           }
          //           .hobby_list {
          //             display: flex;
          //             gap: 4px;
          //             align-items: center;
          //             p {
          //               width: 9px;
          //               height: 8px;
          //               background-color: #1d1d20;
          //               border-radius: 2px;
          //               margin: 0;
          //             }
          //             h6 {
          //               font-size: .5rem;;
          //               margin: 0;
          //               font-weight: 500;
          //               margin: 2px 0;
          //               width: 100%;
          //               word-break: break-all;
          //             }
          //           }
          //         }
          //       }
          //     }
          //   }
          // }

      }
    }
  }

}
</style>